import {ColumnsType} from 'antd/es/table';
import {useNavigate} from 'react-router-dom';
import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import {isActiveTag, tableAddbuttons} from '../../helpers/theme_helper';
import {Routes} from '../../routes/route_helper';
import {LoanModel} from '../../types';

const LoanList = () => {
  const navigate = useNavigate();
  const [loans, loansLoading] = useCollection('loans', 'createdAt');
  const columns: ColumnsType<LoanModel> = [
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
    },
    {
      key: 'institute',
      title: 'ББС',
      dataIndex: 'institute',
      render: (institute: any) => <span>{institute?.name || ''}</span>,
    },
    {
      key: 'active',
      title: 'Идэвхитэй эсэх',
      dataIndex: 'active',
      render: (active: boolean) => isActiveTag(active),
    },
    {
      key: 'min',
      title: 'Доод лимит',
      dataIndex: 'min',
      render: (min: any) => <span>{min} сая</span>,
    },
    {
      key: 'limit',
      title: 'Дээд лимит',
      dataIndex: 'limit',
      render: (limit: any) => <span>{limit} сая</span>,
    },
    {
      key: 'durationMonth',
      title: 'Хугацаа',
      dataIndex: 'durationMonth',
      render: (durationMonth: any) => <span>{durationMonth} сар</span>,
    },
    {
      key: 'interest',
      title: 'Хүү',
      dataIndex: 'interest',
      render: (interest: any) => <span>{interest} %</span>,
    },
  ];

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.loanDetail, {id: d.uid}));
  };

  const onClickAdd = () => {
    navigate(Routes.loanCreate);
  };

  return (
    <DataTable
      tableName="Зээлийн мэдээлэл"
      columns={columns}
      data={loans}
      loading={loansLoading}
      onClickRow={onClickRow}
      headerWidgers={tableAddbuttons('Зээл нэмэх', onClickAdd)}
    />
  );
};
export default LoanList;
