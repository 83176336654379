import React, {useState} from 'react';
import {Button, Input, Modal, Alert, message} from 'antd';
import * as _ from 'lodash';

import {AuthRoles} from '../../types';
import {expression} from '../../constants/app_data';
import LogoImage from '../../assets/images/logo.png';
import {createAppUser} from '../../helpers/firebase_functions';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
};

const AdminAddModal: React.FC<Props> = ({open = false, toggle}) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setEmail('');
    setError('');
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (_.isEmpty(email) || !expression.test(email) || loadingSave)
      return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    const result = await createAppUser(email, AuthRoles.ADMIN, {});
    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.msg,
      });
    } else {
      onCancel();
      messageApi.open({
        type: 'success',
        content: 'Админы бүртгэл нэмэгдлээ.',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            className="rounded-xl"
            type="primary"
            onClick={onSave}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Finy Админ
            </div>
            <Input
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Админ имейл"
              className="mb-3"
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminAddModal;
