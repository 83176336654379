import {useNavigate, useParams} from 'react-router-dom';
import {saveData, useDoc} from '../../helpers/firestore';
import {Button, Col, Form, message, Input, Row, Spin} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {Routes} from '../../routes/route_helper';
import {useEffect, useState} from 'react';
import * as _ from 'lodash';

const CompanyDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [company, companyLoading] = useDoc(`shops/${id ?? 0}`);

  const goBack = () => navigate(Routes.shops);

  useEffect(() => {
    if (!_.isEmpty(company)) {
      form.setFieldsValue(company);
    }
  }, [company]);

  const onFinish = async (values: any) => {
    setLoading(true);
    if (_.isEmpty(id)) return;
    await saveData('shops', id, {...values}, false);
    setLoading(false);
    messageApi.open({
      type: 'success',
      content: 'Дэлгүүрийн мэдээлэл хадгалагдлаа.',
    });
  };

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex items-center gap-x-4">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Дэлгүүрийн мэдээлэл
        </Button>
      </div>
      <hr />
      <Spin spinning={companyLoading}>
        <div className="card-container p-6 my-8">
          <Form form={form} onFinish={onFinish} scrollToFirstError>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <div className="my-1">Нэвтрэх имейл хаяг</div>
                <Form.Item name="email">
                  <Input disabled className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <div className="my-1">Нэр</div>
                <Form.Item
                  rules={[
                    {required: true, message: 'Дэлгүүрийн нэр оруулна уу'},
                  ]}
                  name="name"
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <div className="my-1">Утас</div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Дэлгүүрийн утасны дугаар оруулна уу',
                    },
                  ]}
                  name="phone"
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <div className="my-1">Фэйсбүүк хаяг</div>
                <Form.Item name="fb">
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <div className="my-1">Хаяг байршил</div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Дэлгүүрийн хаяг, байршил оруулна уу',
                    },
                  ]}
                  name="address"
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <div className="my-1">Богино тайлбар</div>
                <Form.Item
                  rules={[{required: true, message: 'Тайлбар оруулна уу'}]}
                  name="shortDesc"
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} className="flex justify-end mt-5">
                <Button
                  type="primary"
                  className="w-full sm:w-48"
                  shape="round"
                  htmlType="submit"
                  disabled={loading || companyLoading}
                  loading={loading}
                >
                  Хадгалах
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default CompanyDetail;
