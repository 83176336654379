import React, {useEffect, useState} from 'react';
import {Button, Input, Modal, Alert, message, Row, Col} from 'antd';
import * as _ from 'lodash';

import LogoImage from '../../assets/images/logo.png';
import {AuthRoles, InstituteModel} from '../../types';
import {updateData} from '../../helpers/firestore';

import Upload from 'antd/es/upload';
import {isValidUrl} from '../../helpers/utils';
import {uploadImageBlob} from '../../helpers/firebase_storage';
import {expression} from '../../constants/app_data';
import {createAppUser} from '../../helpers/firebase_functions';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data?: InstituteModel;
};

const InstituteDetail: React.FC<Props> = ({data, open = false, toggle}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState<string>('');
  const [image, setImage] = useState<string | Blob>();
  const [cover, setCover] = useState<string | Blob>();

  const [error, setError] = useState('');
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setName('');
    setEmail('');
    setImage('');
    setCover('');
    setError('');
    setLoadingDelete(false);
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (
      _.isEmpty(name) ||
      _.isEmpty(email) ||
      _.isEmpty(image) ||
      _.isEmpty(cover) ||
      !expression.test(email) ||
      loadingDelete ||
      loadingSave
    )
      return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    let imageUrl = image?.toString();
    if (image instanceof Blob) {
      imageUrl = await handleUpload(image as Blob);
    }
    let coverUrl = cover?.toString();
    if (cover instanceof Blob) {
      coverUrl = await handleUpload(cover as Blob);
    }
    const accountResult = await createAccount(imageUrl || '', coverUrl || '');
    if (!accountResult) {
      setLoadingSave(false);
    } else {
      onCancel();
      messageApi.open({
        type: 'success',
        content: 'ББС ийн мэдээлэл хадгалагдлаа.',
      });
    }
  };

  const createAccount = async (image: string, cover: string) => {
    if (data == null) {
      const result = await createAppUser(email, AuthRoles.FINANCIAL_INSTITUTE, {
        name,
        email,
        image,
        coverImage: cover,
      });
      if (!result.status) {
        messageApi.open({
          type: 'error',
          content: result.msg,
        });
      }
      return result.status;
    }
    if (!_.isEmpty(data?.uid)) {
      await updateData(`institutes/${data.uid}`, {
        name,
        image,
        coverImage: cover,
      });
      return true;
    }
    return false;
  };

  const handleUpload = async (file: Blob) => {
    try {
      const result = await uploadImageBlob(file, 'images/institutes');
      return result as string;
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: e ?? 'Зураг upload хийхэд алдаа гарлаа.',
      });
      return '';
    }
  };

  useEffect(() => {
    if (data != null) {
      setName(data.name);
      setEmail(data.email);
      setImage(data.image);
      setCover(data.coverImage);
    }
  }, [data]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            className="rounded-xl"
            type="primary"
            onClick={onSave}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Санхүүгийн байгууллага
            </div>
            <Row gutter={8}>
              <Col xs={12} sm={8}>
                <Upload
                  name={`image-${data?.uid}`}
                  listType="picture-card"
                  className="w-full"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    setImage(file);
                    return false;
                  }}
                >
                  {_.isEmpty(image) ? (
                    <div>Зураг</div>
                  ) : !isValidUrl(image?.toString() || '') ? (
                    <img
                      key={`image-${data?.uid}`}
                      className="w-full h-full object-cover rounded-sm"
                      src={URL.createObjectURL(image as Blob)}
                      alt="Thumb"
                    />
                  ) : (
                    <img
                      key={`image-${data?.uid}`}
                      className="w-full h-full object-cover rounded-sm"
                      src={image?.toString()}
                      alt="Thumb"
                    />
                  )}
                </Upload>
              </Col>
              <Col xs={12} sm={16}>
                <Upload
                  name={`cover-${data?.uid}`}
                  listType="picture-card"
                  className="w-full"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    setCover(file);
                    return false;
                  }}
                >
                  {_.isEmpty(cover) ? (
                    <div>Cover Зураг</div>
                  ) : !isValidUrl(cover?.toString() || '') ? (
                    <img
                      key={`cover-${data?.uid}`}
                      className="w-full h-full object-cover rounded-sm"
                      src={URL.createObjectURL(cover as Blob)}
                      alt="Thumb"
                    />
                  ) : (
                    <img
                      key={`cover-${data?.uid}`}
                      className="w-full h-full object-cover rounded-sm"
                      src={cover?.toString()}
                      alt="Thumb"
                    />
                  )}
                </Upload>
              </Col>
            </Row>
            <div className="my-3 bg-surface h-[1px]"></div>
            <div className="flex pt-2">
              <div className="flex-1">
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Нэр"
                  className="mb-3"
                />
                <Input
                  value={email}
                  type="email"
                  disabled={data != null}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Админ имейл"
                  className="mb-3"
                />
              </div>
            </div>
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InstituteDetail;
