import React, {useEffect, useState} from 'react';
import {Button, Input, message} from 'antd';
import {FormGroup} from '../../types';
import {DeleteOutlined} from '@ant-design/icons';
import {updateData} from '../../helpers/firestore';
import * as _ from 'lodash';

type Props = {
  docName: string;
  fieldName: string;
  docUid?: string | undefined;
  certFormGroups: FormGroup[];
};

const FormGroupComp: React.FC<Props> = ({
  certFormGroups,
  docUid,
  docName,
  fieldName,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [formGroups, setFormGroups] = useState([] as FormGroup[]);

  const addGroups = () => {
    setFormGroups([...formGroups, {id: formGroups.length, name: ''}]);
  };

  const editGroup = (groupId: number, e: any) => {
    setFormGroups(
      formGroups.map((current) =>
        current.id === groupId ? {id: groupId, name: e.target.value} : current,
      ),
    );
  };

  const removeGroup = (groupId: number) => {
    setFormGroups(formGroups.filter((f) => f.id !== groupId));
  };

  const saveGroups = async () => {
    if (_.isEmpty(docUid) || _.isEmpty(formGroups)) {
      messageApi.open({
        type: 'error',
        content: 'Алдаа гарлаа',
      });
      return;
    }
    setLoadingGroups(true);
    let data: any = {};
    data[fieldName] = formGroups;
    await updateData(`${docName}/${docUid}`, data);
    setLoadingGroups(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  useEffect(() => {
    setFormGroups(certFormGroups);
  }, [certFormGroups]);

  return (
    <>
      {contextHolder}
      {formGroups.map((g) => (
        <Input
          key={`cert-form-group-${g.id}`}
          value={g.name}
          addonAfter={
            <DeleteOutlined
              className="mx-2"
              onClick={() => removeGroup(g.id)}
            />
          }
          onChange={(e) => editGroup(g.id, e)}
          placeholder="Нэр"
          className="mb-3"
        />
      ))}
      <div className="flex mt-3 gap-x-3 mb-10">
        <Button
          key="submit"
          size="small"
          shape="round"
          className="w-48"
          onClick={addGroups}
        >
          Нэмэх
        </Button>
        <Button
          key="save"
          size="small"
          shape="round"
          type="primary"
          className="w-48"
          loading={loadingGroups}
          disabled={_.isEmpty(docUid)}
          onClick={saveGroups}
        >
          Хадгалах
        </Button>
      </div>
    </>
  );
};

export default FormGroupComp;
