import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import DataTable from '../../components/table/Index';
import {cityData} from '../../constants/city_data';
import * as _ from 'lodash';
import {uniq} from 'lodash';
import {Input, Tag} from 'antd';
import {firestore} from '../../firebase';
import {Routes} from '../../routes/route_helper';
import {RequestStatus} from '../../types';

const FinyRequests = () => {
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [requests, setRequests] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]);
  const [fieldRequests, setFields] = useState<any[]>([]);
  const [loadingData, setLoading] = useState(false);
  const [certificates, setCertificates] = useState<any[]>([]);
  const [search, setSearch] = useState('');

  const columns: ColumnsType<any> = [
    {
      key: 'userPhone',
      title: 'Хэрэглэгч',
      dataIndex: 'userPhone',
    },
    {
      key: 'userCity',
      title: 'Аймаг',
      dataIndex: 'userCity',
      render: (userCity: string) =>
        cityData.find((c) => c.code === userCity)?.name || '',
    },
    {
      key: 'requests',
      title: 'Хүсэлтүүд',
      dataIndex: 'requests',
      render: (_, record) => (
        <div>
          {uniq(record.requests.map((r: any) => r.type)).map((r: any) => {
            const reqs = (record.requests as any[]).filter(
              (req) => req.type === r,
            );
            return (
              <Tag
                key={`${record.uid}-${r}`}
                color={
                  reqs.filter((req) => req.status === RequestStatus.verified)
                    .length === reqs.length
                    ? 'success'
                    : 'default'
                }
              >
                {r}
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      key: 'sentAt',
      title: 'Огноо',
      dataIndex: 'sentAt',
      render: (sentAt: Timestamp) => convertDateTimeFromTimestamp(sentAt),
    },
  ];

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.finyRequestDetail, {id: d.uid}));
  };

  const prepareData = () => {
    const field = (fieldRequests as any[]).map((l) => ({
      ...l,
      assigned: !_.isEmpty(l.assignedTo),
      type: 'Талбайн түүх',
    }));
    const certificate = (certificates as any[]).map((c) => ({
      ...c,
      assigned: !_.isEmpty(c.assignedTo),
      type: c.isContract ? 'Амлалт үүргийн гэрээ' : 'Гэрчилгээ баталгаажуулах',
    }));
    const data = [...field, ...certificate];
    const sorted = data.sort(function (x, y) {
      return y.sentAt - x.sentAt;
    });

    const usersRequest: any[] = [];
    const uniqueUsers = uniq(sorted.map((s) => s.userUid));
    uniqueUsers.forEach((u) => {
      const requests = sorted.filter((s) => s.userUid === u);
      usersRequest.push({
        uid: u,
        userPhone: requests[0].userPhone,
        userCity: requests[0].userCity,
        sentAt: requests[0].sentAt,
        requests: requests.map((r) => ({status: r.status, type: r.type})),
      });
    });

    setRequests(usersRequest);
    setShowData(usersRequest);
  };

  const getData = async () => {
    setLoading(true);
    const fieldPromise = firestore
      .collection('fieldRequests')
      .where('status', '==', RequestStatus.sent)
      .orderBy('sentAt', 'desc');
    const certPromise = firestore
      .collection('certificateRequests')
      .where('status', '==', RequestStatus.sent)
      .orderBy('sentAt', 'desc');
    const data = await Promise.all([fieldPromise.get(), certPromise.get()]);
    setFields(data[0].docs.map((d) => ({...d.data(), uid: d.id})));
    setCertificates(data[1].docs.map((d) => ({...d.data(), uid: d.id})));
    setLoading(false);
  };

  const searchUser = () => {
    setShowData(requests.filter((r) => r.userPhone.includes(search)));
  };

  useEffect(() => {
    getData();
  }, [userRole]);

  useEffect(() => {
    prepareData();
  }, [fieldRequests, certificates]);

  return (
    <DataTable
      tableName="Фини хүсэлтүүд"
      columns={columns}
      data={showData}
      loading={loadingData || _.isEmpty(userRole)}
      onClickRow={onClickRow}
      reloadData={getData}
      onSearch={searchUser}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Утасны дугаар"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />,
      ]}
    />
  );
};

export default FinyRequests;
