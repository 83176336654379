import {Button, Modal, Spin} from 'antd';
import {
  BlobProvider,
  Document,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import {Document as DocumentReader, Page as PageReader, pdfjs} from 'react-pdf';
import {useState} from 'react';
import ContractDocument from './ContractDocument';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import CertContractDocument from './CertContractDocument';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ContractPDF = ({open = false, toggle, contract = {}}) => {
  const [pages, setPages] = useState(0);

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    fontWeight: 'normal',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    fontWeight: 'medium',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 'bold',
  });

  const handleLoadSuccess = (data) => {
    setPages(data.numPages);
  };

  return (
    <Modal
      open={open}
      onCancel={toggle}
      className="pdf-modal"
      width={Math.min(900, window.innerWidth)}
      closeIcon={<></>}
      centered
      footer={[]}
    >
      <BlobProvider
        document={
          <Document>
            {contract.loanContract ? (
              <ContractDocument contract={contract} />
            ) : (
              <CertContractDocument contract={contract} />
            )}
          </Document>
        }
      >
        {({blob, url, loading}) => {
          return loading ? (
            <div className="mt-20 text-center">
              <Spin />
            </div>
          ) : (
            <DocumentReader
              file={url}
              loading={<></>}
              onLoadSuccess={handleLoadSuccess}
            >
              {Array.from({length: pages}).map((p, i) => (
                <PageReader
                  key={`page-${i}]`}
                  renderTextLayer={false}
                  loading={<></>}
                  pageNumber={i + 1}
                  width={Math.min(900, window.innerWidth)}
                />
              ))}
            </DocumentReader>
          );
        }}
      </BlobProvider>
      <div className="text-right">
        <Button key={1} shape="round" onClick={toggle} className="m-3">
          Буцах
        </Button>
      </div>
    </Modal>
  );
};

export default ContractPDF;
