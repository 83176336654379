import React, {useEffect, useState} from 'react';
import {Button, Modal, Checkbox} from 'antd';

import {TableSetting} from '../../types';

type Props = {
  setting: TableSetting[];
  changeSetting: (value: TableSetting[]) => void;
  open: boolean;
  toggleOpen: (value: boolean) => void;
};

const TableSettingModal: React.FC<Props> = ({
  setting,
  changeSetting,
  open,
  toggleOpen,
}) => {
  const [values, setValues] = useState([] as TableSetting[]);
  useEffect(() => setValues(setting), [setting, open]);

  const onCheckOption = (key: string, value: boolean) => {
    setValues(
      values.map((v) => {
        if (v.column.key === key) {
          return {...v, show: value};
        } else {
          return v;
        }
      }),
    );
  };

  const onCancel = () => toggleOpen(false);
  const onSubmit = () => {
    changeSetting(values);
    toggleOpen(false);
  };

  return (
    <Modal
      open={open}
      title="Хүснэгтийн тохиргоо"
      onCancel={onCancel}
      className="info-modal"
      centered
      footer={[
        <Button key="cancel" className="rounded-xl" onClick={onCancel}>
          Цуцлах
        </Button>,
        <Button
          key="submit"
          className="rounded-xl"
          type="primary"
          onClick={onSubmit}
        >
          Хадгалах
        </Button>,
      ]}
    >
      <div className="dialog-body pt-3">
        {values.map((v) => (
          <div
            key={`settings-${v.column.key}`}
            className="flex justify-between items-center px-5 py-3 border-t border-x-0 border-b-0 border-solid border-surface"
          >
            <div className="">{v.column.title}</div>
            <Checkbox
              className="flex items-center mr-2"
              checked={v.show}
              onChange={(e) => {
                onCheckOption(v.column.key, e.target.checked);
              }}
            ></Checkbox>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TableSettingModal;
