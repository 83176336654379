const AccountantReport = () => {
  return (
    <div>
      Тайлан харах (нягтлангаас лавлах нягтлангийн ажилтай холбоотой тайлан
      бичилт)
    </div>
  );
};

export default AccountantReport;
