import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {useState} from 'react';
import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {AuditCompanyModel} from '../../types';
import AuditAdminAdd from './AdminAdd';
import {tableAddbuttons} from '../../helpers/theme_helper';

const AuditAdmins = () => {
  const [auditCompanies, auditCompaniesLoading] = useCollection(
    'auditCompany',
    'createdAt',
  );
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<AuditCompanyModel> = [
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
    },
    {
      key: 'adminEmail',
      title: 'Админ имейл',
      dataIndex: 'adminEmail',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  return (
    <div>
      <DataTable
        tableName="Аудитын компаниуд"
        columns={columns}
        data={auditCompanies}
        loading={auditCompaniesLoading}
        headerWidgers={tableAddbuttons('Компани нэмэх', toggleModal)}
      />
      <AuditAdminAdd open={open} toggle={toggleModal} />
    </div>
  );
};

export default AuditAdmins;
