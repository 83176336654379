import React, {useEffect, useState} from 'react';
import {Button, Input, Modal, Alert, Select, message} from 'antd';
import * as _ from 'lodash';

import LogoImage from '../../assets/images/logo.png';
import {NotificationModel} from '../../types';
import {cityData} from '../../constants/city_data';
import {getUidFirebase, saveData} from '../../helpers/firestore';
import {sendNotification} from '../../helpers/firebase_functions';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data?: NotificationModel;
};

const NotificationDetail: React.FC<Props> = ({data, open = false, toggle}) => {
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [region, setRegion] = useState<string | undefined>();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setTitle('');
    setDesc('');
    setRegion(undefined);
    setError('');
    setLoading(false);
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (
      _.isEmpty(title) ||
      _.isEmpty(desc) ||
      _.isEmpty(region) ||
      !_.isEmpty(data?.sentAt) ||
      loading ||
      loadingSave
    )
      return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    await saveNotifData(data?.uid);
    setLoadingSave(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдэгдэл хадгалагдлаа.',
    });
  };

  const onSend = async () => {
    if (!validate()) return;
    try {
      setLoading(true);
      const uid = await saveNotifData(data?.uid);
      const result = await sendNotification(uid);
      if (result.status) {
        onCancel();
        messageApi.open({
          type: 'success',
          content: 'Мэдэгдэл илгээгдлээ.',
        });
      } else throw result.msg;
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  };

  const saveNotifData = async (uid: string | undefined) => {
    let uidValue = uid ?? '';
    const isCreate = _.isEmpty(uid);
    if (isCreate) uidValue = await getUidFirebase();
    await saveData(
      'notifications',
      uidValue,
      {
        title,
        desc,
        regionCode: region,
        region: cityData.find((c) => c.code === region)?.name,
      },
      isCreate,
    );
    return uidValue;
  };

  useEffect(() => {
    if (data != null) {
      setTitle(data.title);
      setDesc(data.desc);
      setRegion(data.regionCode);
    }
  }, [data]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="submit"
            loading={loadingSave}
            disabled={!validate()}
            className="rounded-xl"
            onClick={onSave}
          >
            Хадгалах
          </Button>,
          <Button
            key="send"
            loading={loading}
            disabled={!validate()}
            className="rounded-xl"
            type="primary"
            onClick={onSend}
          >
            Илгээх
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Мэдэгдэл илгээх
            </div>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Гарчиг"
              className="mb-3"
            />
            <Input
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Дэлгэрэнгүй"
              className="mb-3"
            />
            <Select
              value={region}
              onChange={(value) => setRegion(value)}
              options={cityData.map((c) => ({
                value: c.code,
                label: c.name,
              }))}
              placeholder="Аймаг"
              className="w-full mb-3"
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationDetail;
