import {useNavigate, useParams} from 'react-router-dom';
import {useCollection, useDoc} from '../../helpers/firestore';
import * as _ from 'lodash';
import {Button, Col, Image, Row, Spin, message} from 'antd';
import {Routes} from '../../routes/route_helper';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {formatCurrency} from '../../helpers/utils';
import {
  AuthRoles,
  ContractModel,
  FieldRequestModel,
  LoanStatus,
  CertificateRequestModel,
  RequestStatus,
} from '../../types';
import {imageFallback} from '../../constants/theme';
import {ColumnsType} from 'antd/es/table';
import {loanRequestTag, requestTag} from '../../helpers/theme_helper';
import {Timestamp} from 'firebase/firestore';
import DataTable from '../../components/table/Index';
import {useContext, useEffect, useState} from 'react';
import RequestFormModal from './RequestFormModal';
import {UserContext} from '../../context/app.contexts';
import {firestore} from '../../firebase';
import ContractPDF from '../contract/ContractPDF';

const LoanRequestDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [request, requestLoading] = useDoc(`loanRequests/${id}`);
  const [acceptingRequest, setAcceptingRequest] = useState(false);
  const [rejectingRequest, setRejectingRequest] = useState(false);
  const [requestUser] = useDoc(`users/${request.userUid ?? '0'}`);
  const [fieldRequests, fieldLoading] = useCollection(
    `users/${request.userUid ?? '0'}/fieldRequests`,
    'year',
    {
      field: 'status',
      op: '==',
      value: RequestStatus.verified,
    },
    undefined,
    true,
  );
  const [contracts, setContracts] = useState<any[]>([]);
  const [certifcates, setCertificates] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = (value: boolean) => setOpenModal(value);
  const [modalChild, setModalChild] = useState<JSX.Element>(<></>);
  const [selected, setSelected] = useState<any | undefined>();
  const [openViewer, setOpenViewer] = useState(false);
  const toggleModalViewer = () => setOpenViewer(!openViewer);
  const goBack = () => navigate(Routes.loanRequests);

  const certColumns: ColumnsType<CertificateRequestModel> = [
    {
      key: 'certNumber',
      title: 'Certificate дугаар',
      dataIndex: 'certNumber',
    },
  
    {
      key: 'certificateName',
      title: 'Баталгаажуулалт',
      dataIndex: 'certificateDesc',
      responsive: ['lg'],
    },
    {
      key: 'certValidDate',
      title: 'Огноо',
      dataIndex: 'certValidDate',
      render: (certValidDate: Timestamp) => convertDateTimeFromTimestamp(certValidDate),
      responsive: ['lg'],
    },
    {
      key: 'verifiedBy',
      title: 'Баталгаажуулалсан',
      dataIndex: 'verifiedBy',

    },
    {
      key: 'vertifiedAt',
      title: 'Баталгаажуулсан Огноо',
      dataIndex: 'vertifiedAt',
      render: (vertifiedAt: Timestamp) => convertDateTimeFromTimestamp(vertifiedAt),
      responsive: ['lg'],
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <div
          className="text-info cursor-pointer"
          onClick={() => {
            window.open(record.certificateImage)
          }}
        >
          Харах
        </div>
      ),
    },
  ];


  const contractColumns: ColumnsType<ContractModel> = [
    {
      key: 'contractNumber',
      title: 'Гэрээний дугаар',
      dataIndex: 'contractNumber',
    },
    {
      key: 'loanContract',
      title: 'Гэрээний төрөл',
      dataIndex: 'loanContract',
      render: (loanContract: boolean) =>
        loanContract ? 'Амлалт, үүргийн гэрээ' : 'Баталгаажуулалтын гэрээ',
    },
    {
      key: 'certificateName',
      title: 'Баталгаажуулалт',
      dataIndex: 'certificateName',
      responsive: ['lg'],
    },
    {
      key: 'signedAt',
      title: 'Огноо',
      dataIndex: 'signedAt',
      render: (signedAt: Timestamp) => convertDateTimeFromTimestamp(signedAt),
      responsive: ['lg'],
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <div
          className="text-info cursor-pointer"
          onClick={() => {
            setSelected(record);
            toggleModalViewer();
          }}
        >
          Харах
        </div>
      ),
    },
  ];

  const fieldColumns: ColumnsType<FieldRequestModel> = [
    {
      key: 'userPhone',
      title: 'Хэрэглэгч',
      dataIndex: 'userPhone',
    },
    {
      key: 'year',
      title: 'Он',
      dataIndex: 'year',
      render: (year: number) => `${year} оны талбайн түүх`,
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => requestTag(status),
    },
    {
      key: 'vertifiedAt',
      title: 'Баталгаажсан',
      dataIndex: 'vertifiedAt',
      render: (vertifiedAt: Timestamp) =>
        convertDateTimeFromTimestamp(vertifiedAt),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <Button
          size="small"
          type="link"
          onClick={() => {
            setModalChild(getFieldModalChild(record));
            toggleModal(true);
          }}
        >
          <span className="text-sm">Харах</span>
        </Button>
      ),
    },
  ];

  const getContracts = async () => {
    const data = await firestore
      .collection('contracts')
      .where('userUid', '==', request.userUid)
      .orderBy('signedAt', 'desc')
      .get();
    setContracts(data.docs.map((d) => ({...d.data(), uid: d.id})));
  };

  const getFieldModalChild = (request: any) => {
    return (
      <div key={request.uid} className="mt-5">
        <div className="text-lg font-medium">
          {request.year} оны талбайн түүх
        </div>
        <div className="bg-surface my-3 h-[1px]"></div>
        {!_.isEmpty((request as FieldRequestModel).openFieldData) && (
          <div className="card-container p-4">
            <div className="text-lg font-medium mb-5">
              Ил талбай ({(request as FieldRequestModel).year} он)
            </div>
            {(
              JSON.parse((request as FieldRequestModel).openFieldData) as any[]
            ).map((q, i) => {
              return (
                <div className="mb-3" key={`question-${i}`}>
                  <div className="mb-1 text-gray text-sm">{q.question}</div>
                  {typeof q.answer == 'object' ? (
                    <div className="mb-1">
                      {(q.answer as any[]).map((f, fi) => (
                        <div
                          className="border border-solid border-surface mt-3 p-3 rounded-md"
                          key={`first-${fi}`}
                        >
                          {(f as any[]).map((s, si) => {
                            return (
                              <div className="mb-3" key={`second-${si}`}>
                                <div className="mb-1 text-gray text-sm">
                                  {s.question}:
                                </div>
                                <div>{s.answer}</div>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="mb-1">{q.answer}</div>
                  )}
                </div>
              );
            })}
            <div className="flex flex-wrap gap-3">
              {(request.fileUrls ?? []).map((i: any) => (
                <Image
                  key={i}
                  width={200}
                  height={200}
                  className="object-cover"
                  src={i}
                  fallback={imageFallback}
                />
              ))}
            </div>
          </div>
        )}
        {!_.isEmpty((request as FieldRequestModel).greenhouseData) && (
          <div className="card-container p-4 mt-4">
            <div className="text-lg font-medium mb-5">
              Хүлэмжийн талбай ({(request as FieldRequestModel).year} он)
            </div>
            {(
              JSON.parse((request as FieldRequestModel).greenhouseData) as any[]
            ).map((q, i) => {
              return (
                <div className="mb-3" key={`question-${i}`}>
                  <div className="mb-1 text-gray text-sm">{q.question}</div>
                  {typeof q.answer == 'object' ? (
                    <div className="mb-1">
                      {(q.answer as any[]).map((f, fi) => (
                        <div
                          className="border border-solid border-surface mt-3 p-3 rounded-md"
                          key={`first-${fi}`}
                        >
                          {(f as any[]).map((s, si) => {
                            return (
                              <div className="mb-3" key={`second-${si}`}>
                                <div className="mb-1 text-gray text-sm">
                                  {s.question}:
                                </div>
                                <div>{s.answer}</div>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="mb-1">{q.answer}</div>
                  )}
                </div>
              );
            })}
            <div className="flex flex-wrap gap-3">
              {(request.greenFileUrls ?? []).map((i: any) => (
                <Image
                  key={i}
                  width={200}
                  height={200}
                  className="object-cover"
                  src={i}
                  fallback={imageFallback}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const acceptRequest = async () => {
    if (_.isEmpty(request) || _.isEmpty(request.userUid)) return;
    setAcceptingRequest(true);
    await firestore
      .doc(`users/${request.userUid ?? '0'}/loanRequests/${id}`)
      .set(
        {
          currentStatus: LoanStatus.accepted,
        },
        {merge: true},
      );
    setAcceptingRequest(false);
  };

  const rejectRequest = async () => {
    const result = window.confirm(
      'Зээлийн хүсэлт цуцлахдаа итгэлтэй байна уу?',
    );
    if (!result) return;
    setRejectingRequest(true);
    await firestore
      .doc(`users/${request.userUid ?? '0'}/loanRequests/${id}`)
      .set(
        {
          currentStatus: LoanStatus.rejected,
        },
        {merge: true},
      );
    setAcceptingRequest(false);
    messageApi.open({
      type: 'success',
      content: 'Зээлийн хүсэлт цуцлагдлаа.',
    });
  };
  const getCertificates = async ()=>{
    const certs = await firestore
    .collection(`certificateRequests`)
    .where('status', '==',  RequestStatus.verified)
    .where('userUid', '==', request.userUid)
    .orderBy('sentAt', 'desc').get()
    console.log(certs.docs)
    setCertificates(certs.docs.map((d) => ({
      ...d.data(),
      uid: d.id,
    })));

  }

  useEffect(() => {
    if (!_.isEmpty(request.userUid)) {
      getContracts();
      getCertificates();
    }
  }, [request]);

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex flex-wrap items-center gap-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Зээлийн хүсэлт
        </Button>
        {userRole === AuthRoles.FINANCIAL_INSTITUTE && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Button
              danger
              shape="round"
              onClick={rejectRequest}
              disabled={
                rejectingRequest ||
                acceptingRequest ||
                requestLoading ||
                [LoanStatus.rejected, LoanStatus.accepted].includes(
                  request.currentStatus,
                )
              }
              loading={rejectingRequest}
            >
              Цуцлах
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={acceptRequest}
              loading={acceptingRequest}
              disabled={
                rejectingRequest ||
                acceptingRequest ||
                requestLoading ||
                [LoanStatus.rejected, LoanStatus.accepted].includes(
                  request.currentStatus,
                )
              }
            >
              Зээл олгосон
            </Button>
          </div>
        )}
      </div>
      <hr />
      <Spin spinning={requestLoading || _.isEmpty(requestUser)}>
        <Row gutter={[16, 16]} className="my-8">
          <Col xs={24} lg={12}>
            <div className="card-container p-6 h-full">
              <div className="text-lg font-medium"> Хэрэглэгчийн мэдээлэл</div>
              <div className="mt-5 text-sm text-gray">Нэр</div>
              <div className="mt-1">
                {requestUser.userType === 'citizen'
                  ? `${requestUser.lastname}, ${requestUser.firstname}`
                  : requestUser.companyName}{' '}
                ({requestUser.userType === 'citizen' ? 'Хувь хүн' : 'Компани'})
              </div>
              <div className="mt-5 text-sm text-gray">Регистр</div>
              <div className="mt-1">{requestUser.registerNumber}</div>
              <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
              <div className="mt-1">{requestUser.phone}</div>
              <div className="mt-5 text-sm text-gray">Аймаг, сум</div>
              <div className="mt-1">
                {requestUser.city}, {requestUser.district}
              </div>
              <div className="mt-5 text-sm text-gray">НТ дугаар</div>
              <div className="mt-1">{requestUser.ntDugaar}</div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="card-container p-6 h-full">
              <div className="text-lg font-medium">
                Зээлийн хүсэлтийн мэдээлэл
              </div>
              <div className="mt-5 text-sm text-gray">Зээлийн нэр</div>
              <div className="mt-1">{request.loanName}</div>
              <div className="mt-5 text-sm text-gray">Зээлийн хэмжээ</div>
              <div className="mt-1">{formatCurrency(request.loanAmount)}</div>
              <div className="mt-5 text-sm text-gray">Зээлийн зорилго</div>
              <div className="mt-1">{request.loanPurpose}</div>
              <div className="mt-5 text-sm text-gray">
                Хүсэлт илгээсэн огноо
              </div>
              <div className="mt-1">
                {convertDateTimeFromTimestamp(request.sentAt)}
              </div>
              <div className="mt-5 text-sm text-gray">Хүсэлтийн статус</div>
              <div className="mt-1">
                {loanRequestTag(request.currentStatus)}
              </div>
            </div>
          </Col>
        </Row>
        <div className="mt-16"></div>

        {contracts.length>0 && (
        <DataTable
        tableName="Гэрээ"
        columns={contractColumns}
        data={contracts}
      />
              )}
      
        <div className="mt-8"></div>
        <DataTable
          tableName="Certificates Баталгаажуулалт"
          columns={certColumns}
          data={certifcates}
          loading={fieldLoading}
        />
        <div className="mt-8"></div>
        <DataTable
          tableName="Талбайн түүх"
          columns={fieldColumns}
          data={fieldRequests}
          loading={fieldLoading}
        />
        <RequestFormModal
          open={openModal}
          toggle={toggleModal}
          children={modalChild}
        />
        <ContractPDF
          open={openViewer}
          toggle={toggleModalViewer}
          contract={selected}
        />
      </Spin>
    </div>
  );
};

export default LoanRequestDetail;
