import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {useState} from 'react';
import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {AdminModel} from '../../types';
import AdminAddModal from './AdminAdd';
import {tableAddbuttons} from '../../helpers/theme_helper';

const FinyAdmins = () => {
  const [appAdmins, adminsLoading] = useCollection('appAdmin', 'createdAt');
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<AdminModel> = [
    {
      key: 'email',
      title: 'Имейл',
      dataIndex: 'email',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  return (
    <div>
      <DataTable
        tableName="Админ хэрэглэгчид"
        columns={columns}
        data={appAdmins}
        loading={adminsLoading}
        headerWidgers={tableAddbuttons('Админ нэмэх', toggleModal)}
      />
      <AdminAddModal open={open} toggle={toggleModal} />
    </div>
  );
};

export default FinyAdmins;
