import {Timestamp} from 'firebase/firestore';

export enum AuthRoles {
  ADMIN = 'admin',
  FINANCIAL_INSTITUTE = 'financial_institute',
  SHOP = 'shop',
  ANONYMOUS = 'anonymous',
}

export const AUDIT_ADMIN = 'audit_admin';

export type RouteModel = {
  path: string;
  component: () => JSX.Element;
};

export enum LoanStatus {
  loanCreated = 'loanCreated',
  sentRequest = 'sentRequest',
  rejected = 'rejected',
  accepted = 'accepted',
}

export enum RequestStatus {
  created = 'created',
  sent = 'sent',
  verified = 'verified',
  rejected = 'rejected',
}

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  DECLINED = 'DECLINED',
}

export type UserModel = {
  uid: string;
  email: string;
  phone: string;
  userType: string;
  active?: boolean;
  registerNumber: string;
  companyName: string;
  firstname: string;
  lastname: string;
  gender: string;
  thumbnail: string;
  city: string;
  cityCode: string;
  district: string;
  street: string;
  ntDugaar: string;
  createdAt: Timestamp;
};

export type OtpModel = {
  type: string;
  phone: string;
  otpCode: number;
  isVerified: boolean;
  createdAt: Timestamp;
};

export type NotificationModel = {
  uid: string;
  region?: string;
  regionCode?: string;
  title: string;
  desc: string;
  status: string;
  type: string;
  sentAt: Timestamp | undefined;
  createdAt: Timestamp;
};

export type InstituteModel = {
  uid: string;
  name: string;
  email: string;
  admins?: string[];
  image: string;
  coverImage: string;
  createdAt: Timestamp;
};

export type LoanModel = {
  uid: string;
  name: string;
  desc: string;
  min: number;
  limit: number;
  active: boolean;
  durationMonth: number;
  interest: number;
  certificateUids: number[];
  fieldVerification: number[];
  requirements: string[];
  documents: string[];
  partnerDocuments: string[];
  institute: InstituteModel;
  instituteUid: string;
  createdAt: Timestamp;
};

export type AdminModel = {
  email: string;
  createdAt: Timestamp;
};

export type CertificateModel = {
  uid: string;
  name: string;
  desc: string;
  detail: string;
  image: string;
  logo: string;
  groups: FormGroup[];
  applicationGroups: FormGroup[];
  selfEvaluation: boolean;
};

export type FormGroup = {
  id: number;
  name: string;
};

export type FormQuestion = {
  uid: string;
  groupId?: number;
  question?: string;
  type?: string;
  placeholder?: string;
  options?: QuestionOptions[];
  infoText?: string;
};

export type QuestionOptions = {
  id: number;
  value: string;
  score: number;
};

export type AuditCompanyModel = {
  uid: string;
  name: string;
  email: string;
  createdAt: Timestamp;
};

export type LoanRequestModel = {
  uid: string;
  loanUid: string;
  loanName: string;
  instituteName: string;
  instituteImage: string;
  instituteUid: string;
  limit: number;
  min: number;
  fieldVerification: string[];
  certificateUids: string[];
  createdAt: Timestamp;
  verifiedAt: Timestamp;
  loanAmount: number;
  loanPurpose: number;
  currentStatus: string;
  verifiedAllRequests: string;
  paid?: boolean;
  userPhone: string;
};

export type ContractModel = {
  uid: string;
  contractNumber: number;
  certificateName: string;
  certificateUid: string;
  userCity: string;
  userDistrict: string;
  userName: string;
  userPhone: string;
  userRegister: string;
  userType: string;
  userUid: string;
  status: string;
  farmerSignatureUrl: string;
  createdAt: Timestamp;
};
export type TableSetting = {
  column: any;
  show: boolean;
};

export type FaqModel = {
  uid: number;
  answer: string;
  question: string;
  groupName: string;
};

export type OrderModel = {
  uid: string;
  status: string;
  amount: number;
  message: string;
  userPhone: string;
  paymentType: string;
  purchaseType: string;
  qpayInvoiceId: string;
  createdAt: Timestamp;
  verifiedAt: Timestamp;
};

export enum FaqTypes {
  AUDITOR = 'auditor',
  FARMER = 'farmer',
}

export type CertificateRequestModel = {
  uid: string;
  sentAt: Timestamp;
  createdAt: Timestamp;
  assignedTo: string;
  certificateName: string;
  certificateDesc: string;
  certificateUid: string;
  isContract?: boolean;
  contractUid?: string;
  certNumber?: string;
  certValidDate?: Timestamp;
  certificateImage?: string;
  requestData?: any;
  status: string;
  userUid: string;
  userCity: string;
  userDistrict: string;
  userPhone: string;
};

export type FieldRequestModel = {
  uid: string;
  year: number;
  sentAt: Timestamp;
  createdAt: Timestamp;
  openFieldData: any;
  greenhouseData: any;
  status: string;
  userUid: string;
  userCity: string;
  userDistrict: string;
  userPhone: string;
  fileUrls: string[];
  greenFileUrls: string[];
};
