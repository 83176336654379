import {Avatar, Button, Tag, message} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {useState} from 'react';
import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {InstituteModel} from '../../types';
import InstituteDetail from './Detail';
import {tableAddbuttons} from '../../helpers/theme_helper';
import AddInstituteUser from './AddUser';
import { addInstituteUser, removeInstituteUser } from '../../helpers/firebase_functions';

const FinancialInstitute = () => {
  const [institutes, institutesLoading] = useCollection(
    'institutes',
    'createdAt',
  );
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const [openUser, setOpenUser] = useState(false);
  const toggleModalUser = () => setOpenUser(!openUser);
  const [selected, setSelected] = useState({} as InstituteModel);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const columns: ColumnsType<InstituteModel> = [
    {
      key: 'image',
      title: 'Зураг',
      dataIndex: 'image',
      render: (imageUrl: string) => <Avatar src={imageUrl} />,
    },
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
    },
    {
      key: 'email',
      title: 'Админ имейл',
      dataIndex: 'email',
    },
    {
      key: 'admins',
      title: 'Хэрэглэгчид',
      dataIndex: 'admins',
      render: (_, record) => (
        <div className='flex flex-wrap w-[150px]'>
          {
            record.admins?.map(a => 
              <Tag className='mb-2 cursor-pointer' key={a} onClick={() => removeUser(record.uid, a)}>{a} <i className="bx bx-x mt-1"></i></Tag>
            )
          }
          <Tag 
            className='cursor-pointer' 
            color="#4CAF4F"
            onClick={() => {
              setSelected(record);
              toggleModalUser();
            }}><i className="bx bx-plus"></i> Бүртгэх</Tag>
        </div>
      ),
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
          <Button
            icon={<i className="bx bx-detail"></i>}
            size="small"
            type="primary"
            className="bg-lightBlue"
            onClick={() => {
              onClickRow(record);
            }}
          />
      ),
    },
  ];

  const removeUser = async(instUid: string, email: string) => {
    const result = window.confirm(`${email} хэрэглэгчийг бүртгэлээс хасах уу ?`)
    console.log(result)
    if (result) {
      setLoading(true);
      const result = await removeInstituteUser(email, instUid);
      if (!result.status) {
        setLoading(false);
        messageApi.open({
          type: 'error',
          content: result.msg,
        });
      } else {
        setLoading(false);
        messageApi.open({
          type: 'warning',
          content: 'Хэрэглэгч хасагдлаа.',
        });
      }
    }
  } 

  const showAddModal = () => {
    setSelected({} as InstituteModel);
    toggleModal();
  };

  const onClickRow = (d: any) => {
    setSelected(d);
    toggleModal();
  };

  return (
    <div>
      {contextHolder}
      <DataTable
        tableName="Санхүүгийн байгууллагууд"
        columns={columns}
        data={institutes}
        loading={institutesLoading || loading}
        headerWidgers={tableAddbuttons('ББС нэмэх', showAddModal)}
      />
      <InstituteDetail data={selected} open={open} toggle={toggleModal} />
      <AddInstituteUser open={openUser} toggle={toggleModalUser} instituteUid={selected.uid}/>
    </div>
  );
};

export default FinancialInstitute;
