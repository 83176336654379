import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {AuthRoles, LoanRequestModel, LoanStatus} from '../../types';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {loanRequestTag} from '../../helpers/theme_helper';
import DataTable from '../../components/table/Index';
import {formatCurrency} from '../../helpers/utils';
import {useNavigate} from 'react-router-dom';
import * as _ from 'lodash';
import {Routes} from '../../routes/route_helper';
import {firestore} from '../../firebase';
import {Input} from 'antd';

const LoanRequests = () => {
  const navigate = useNavigate();
  const {user, userRole} = useContext(UserContext);
  const [requests, setRequests] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]);
  const [userPhone, setUserPhone] = useState('');
  const [loading, setLoading] = useState(true);

  const columns: ColumnsType<LoanRequestModel> = [
    {
      key: 'userPhone',
      title: 'Хэрэглэгч',
      dataIndex: 'userPhone',
    },
    {
      key: 'instituteName',
      title: 'ББС',
      dataIndex: 'instituteName',
    },
    {
      key: 'loanName',
      title: 'Зээл',
      dataIndex: 'loanName',
    },
    {
      key: 'loanAmount',
      title: 'Зээлийн хэмжээ',
      dataIndex: 'loanAmount',
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: 'currentStatus',
      title: 'Статус',
      dataIndex: 'currentStatus',
      render: (currentStatus: string) => loanRequestTag(currentStatus),
    },
    {
      key: 'createdAt',
      title: 'Огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  const onClickRow = (data: any) => {
    if (
      [
        LoanStatus.sentRequest,
        LoanStatus.accepted,
        LoanStatus.rejected,
      ].includes(data.currentStatus)
    ) {
      navigate(Routes.compilePath(Routes.loanRequestDetail, {id: data.uid}));
    }
  };

  const getRequests = async () => {
    setLoading(true);
    let ref = await firestore
      .collection('loanRequests')
      .where('currentStatus', 'in', [
        LoanStatus.sentRequest,
        LoanStatus.accepted,
        LoanStatus.rejected,
      ]);
    if (userRole === AuthRoles.FINANCIAL_INSTITUTE) {
      const token = await user.getIdTokenResult();
      const belongTo = token.claims.belongTo
      ref = ref
        .where('instituteUid', '==', belongTo ?? user.uid)
        .where('verifiedAllRequests', '==', true);
    }
    const data = await ref.orderBy('sentAt', 'desc').get();
    setRequests(data.docs.map((d) => ({...d.data(), uid: d.id})));
    setShowData(data.docs.map((d) => ({...d.data(), uid: d.id})));
    setLoading(false);
  };

  const search = () => {
    setShowData(requests.filter((r: any) => r.userPhone.includes(userPhone)));
  };

  useEffect(() => {
    if (!_.isEmpty(userRole) && !_.isEmpty(user)) {
      getRequests();
    }
  }, [userRole, user]);

  return (
    <DataTable
      tableName="Зээлийн хүсэлтүүд"
      columns={columns}
      data={showData}
      onClickRow={onClickRow}
      loading={loading}
      reloadData={getRequests}
      onSearch={search}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Утасны дугаар"
          value={userPhone}
          onChange={(v) => setUserPhone(v.target.value)}
        />,
      ]}
    />
  );
};

export default LoanRequests;
