import {useNavigate, useParams} from 'react-router-dom';
import {useDoc} from '../../helpers/firestore';
import {Button, Col, Row, Spin, message} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {Routes} from '../../routes/route_helper';
import {emptyText} from '../../helpers/theme_helper';
import {useContext, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {toggleFarmerActive} from '../../helpers/firebase_functions';
import {AuthRoles} from '../../types';
import * as _ from 'lodash';

const FarmerDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [user, userLoading] = useDoc(`users/${id}`);
  const [changingActive, setChangingActive] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const goBack = () => navigate(Routes.farmers);
  const userActive = () => _.get(user, 'active', true);

  const toggleActive = async () => {
    if (_.isEmpty(user) || id == null) return;
    const isActive = userActive();
    setChangingActive(true);
    const result = await toggleFarmerActive(id!);
    if (!result.status) {
      setChangingActive(false);
      messageApi.open({
        type: 'error',
        content: result.msg,
      });
    } else {
      setChangingActive(false);
      messageApi.open({
        type: 'success',
        content: `Тариаланчын зээл авах эрхийг ${
          isActive ? 'идэвхигүй' : 'идэвхитэй'
        } болголоо.`,
      });
    }
  };

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex items-center gap-x-4 mb-3">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Тариаланчийн мэдээлэл
        </Button>
        {[AuthRoles.FINANCIAL_INSTITUTE, AuthRoles.ADMIN].includes(
          userRole,
        ) && (
          <div className="flex-1 text-right">
            <Button
              danger={userActive()}
              shape="round"
              onClick={toggleActive}
              disabled={changingActive || userLoading}
              loading={changingActive}
            >
              {userActive() ? 'Идэвхигүй болгох' : 'Идэвхитэй болгох'}
            </Button>
          </div>
        )}
      </div>
      <hr />
      <Spin spinning={userLoading}>
        <div className="card-container p-6 h-full my-8">
          <div className="text-lg font-medium"> Хэрэглэгчийн мэдээлэл</div>
          <Row>
            <Col xs={24} lg={12}>
              <div className="mt-5 text-sm text-gray">Хэрэглэгчийн төрөл</div>
              <div className="mt-1">
                {userLoading
                  ? emptyText
                  : user.userType === 'citizen'
                  ? 'Хувь хүн'
                  : 'Компани'}
              </div>
              <div className="mt-5 text-sm text-gray">Регистрийн дугаар</div>
              <div className="mt-1">{user.registerNumber || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Нэр</div>
              <div className="mt-1">
                {user.lastname || emptyText} {user.firstname}
              </div>
              {user.userType === 'company' && (
                <>
                  <div className="mt-5 text-sm text-gray">Компаний нэр</div>
                  <div className="mt-1">{user.companyName}</div>
                </>
              )}
            </Col>
            <Col xs={24} lg={12}>
              <div className="mt-5 text-sm text-gray">НТ дугаар</div>
              <div className="mt-1">{user.ntDugaar || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
              <div className="mt-1">{user.phone || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Хаяг</div>
              <div className="mt-1">
                {userLoading
                  ? emptyText
                  : `${user.city}, ${user.district}, ${user.street}`}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default FarmerDetail;
