import React, {useEffect, useState} from 'react';
import {Button, Input, Modal, message, Form, Upload} from 'antd';
import {getUidFirebase, saveData, useDoc} from '../../helpers/firestore';
import * as _ from 'lodash';
import {uploadImageBlob} from '../../helpers/firebase_storage';
import {CertificateModel} from '../../types';
import {isValidUrl} from '../../helpers/utils';

type Props = {
  open?: boolean;
  selected?: string;
  toggle: (value: boolean) => void;
};

const CertificateEditModal: React.FC<Props> = ({
  open = false,
  toggle,
  selected,
}) => {
  const [form] = Form.useForm();
  const [image, setImage] = useState<string | Blob>();
  const [logo, setLogo] = useState<string | Blob>();
  const [certData, certLoading] = useDoc(`certificates/${selected ?? 0}`);

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    form.resetFields();
    setLoading(false);
    toggle(false);
    setImage('');
    setLogo('');
  };

  const onSave = async (values: any) => {
    setLoading(true);
    let docUid = selected;
    if (_.isEmpty(docUid)) {
      docUid = await getUidFirebase();
    }
    let imageUrl = image?.toString();
    let logoUrl = logo?.toString();
    if (image instanceof Blob) imageUrl = await handleUpload(image as Blob);
    if (logo instanceof Blob) logoUrl = await handleUpload(logo as Blob);
    await saveData(
      'certificates',
      docUid,
      {
        ...values,
        logo: logoUrl || '',
        image: imageUrl || '',
      },
      _.isEmpty(selected),
    );
    onCancel();
    messageApi.open({
      type: 'success',
      content: 'Гэрчилгээний мэдээлэл хадгалагдлаа',
    });
  };

  const handleUpload = async (file: Blob) => {
    try {
      const result = await uploadImageBlob(file, 'images/certificate');
      return result as string;
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: e ?? 'Зураг upload хийхэд талдаа гарлаа',
      });
      return '';
    }
  };

  useEffect(() => {
    if (!_.isEmpty(certData)) {
      form.setFieldsValue(certData);
      setImage((certData as CertificateModel).image);
      setLogo((certData as CertificateModel).logo);
    }
  }, [certData]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key={1}
            type="primary"
            shape="round"
            className="w-48"
            disabled={loading || certLoading}
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="px-8 py-3">
            <div className="font-medium text-lg text-center my-5">
              Гэрчилгээний мэдээлэл
            </div>
            <Form form={form} onFinish={onSave} scrollToFirstError>
              <div className="flex">
                <div className="flex-1">
                  <div className="my-2 font-medium">Зураг</div>
                  <Upload
                    name={`image`}
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={(file) => {
                      setImage(file);
                      return false;
                    }}
                  >
                    {_.isEmpty(image) ? (
                      <div className="w-full">Зураг</div>
                    ) : (
                      <img
                        alt="img"
                        className="w-full h-full object-cover rounded-sm"
                        src={
                          !isValidUrl(image?.toString() || '')
                            ? URL.createObjectURL(image as Blob)
                            : image?.toString()
                        }
                      />
                    )}
                  </Upload>
                </div>
                <div className="flex-1">
                  <div className="my-2 font-medium">Лого</div>
                  <Upload
                    name={`logo`}
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={(file) => {
                      setLogo(file);
                      return false;
                    }}
                  >
                    {_.isEmpty(logo) ? (
                      <div className="w-full">Лого</div>
                    ) : (
                      <img
                        alt="img"
                        className="w-full h-full object-cover rounded-sm bg-black"
                        src={
                          !isValidUrl(logo?.toString() || '')
                            ? URL.createObjectURL(logo as Blob)
                            : logo?.toString()
                        }
                      />
                    )}
                  </Upload>
                </div>
              </div>
              <div className="my-2 font-medium">Нэр</div>
              <Form.Item
                rules={[{required: true, message: 'Нэр оруулна уу!'}]}
                name="name"
              >
                <Input />
              </Form.Item>
              <div className="my-2 font-medium">Богино тайлбар</div>
              <Form.Item
                rules={[
                  {required: true, message: 'Богино тайлбар оруулна уу!'},
                ]}
                name="desc"
              >
                <Input />
              </Form.Item>
              <div className="my-2 font-medium">Тайлбар</div>
              <Form.Item initialValue="" name="detail">
                <Input.TextArea showCount maxLength={100} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CertificateEditModal;
