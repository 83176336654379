import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import type {ColumnsType} from 'antd/es/table';
import {NotificationModel} from '../../types';
import {Timestamp} from '@firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {useState} from 'react';
import NotificationDetail from './Detail';
import {tableAddbuttons} from '../../helpers/theme_helper';

const Notifications = () => {
  const [otp, otpLoading] = useCollection('notifications', 'createdAt');
  const [selected, setSelected] = useState({} as NotificationModel);
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<NotificationModel> = [
    {
      key: 'title',
      title: 'Гарчиг',
      dataIndex: 'title',
    },
    {
      key: 'desc',
      title: 'Тайлбар',
      dataIndex: 'desc',
    },
    {
      key: 'region',
      title: 'Аймаг',
      dataIndex: 'region',
    },
    {
      key: 'sentAt',
      title: 'Илгээсэн',
      dataIndex: 'sentAt',
      render: (sentAt?: Timestamp) => convertDateTimeFromTimestamp(sentAt),
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  const showAddModal = () => {
    setSelected({} as NotificationModel);
    toggleModal();
  };

  const onClickRow = (d: any) => {
    setSelected(d);
    toggleModal();
  };

  return (
    <div>
      <DataTable
        tableName="Мэдэгдлүүд"
        columns={columns}
        data={otp}
        loading={otpLoading}
        onClickRow={onClickRow}
        headerWidgers={tableAddbuttons('Мэдэгдэл илгээх', showAddModal)}
        // ]}
      />
      <NotificationDetail data={selected} open={open} toggle={toggleModal} />
    </div>
  );
};

export default Notifications;
