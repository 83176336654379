import Login from '../pages/auth/Login';
import Dashboard from '../pages/dashboard/Index';
import Notifications from '../pages/notifications/Index';
import Otp from '../pages/otp/Index';
import Farmers from '../pages/farmers/List';
import FarmerDetail from '../pages/farmers/Detail';
import FinancialInstitute from '../pages/financialInstitute/List';
import Companies from '../pages/shop/Index';
import CompanyDetail from '../pages/shop/Detail';
import LoanList from '../pages/loan/List';
import LoanDetail from '../pages/loan/Detail';
import {Routes} from './route_helper';
import FinyAdmins from '../pages/settings/Admins';
import FinyFAQ from '../pages/settings/FAQ';
import AuditFAQ from '../pages/settings/AuditFaq';
import FinyInfo from '../pages/settings/Info';
import AuditAdmins from '../pages/auditors/Admins';
import Payments from '../pages/payments/Index';
import CertificateList from '../pages/certificates/List';
import CertificateDetail from '../pages/certificates/Detail';
import LoanRequests from '../pages/loan/Requests';
import NotFound from '../pages/NotFound';
import LoanRequestDetail from '../pages/loan/RequestDetail';
import Clients from '../pages/shop/Clients';
import ShopLoanAccounts from '../pages/shop/LoanAccounts';
import ShopLoanRepayment from '../pages/shop/LoanRepayment';
import ShopLoans from '../pages/shop/Loans';
import ShopTotalLoan from '../pages/shop/report/TotalLoan';
import DueLoans from '../pages/shop/report/DueLoans';
import NonperformingLoans from '../pages/shop/report/NonperformingLoans';
import OverdueLoans from '../pages/shop/report/OverdueLoans';
import ShopLoanReport from '../pages/shop/report/LoanReport';
import LikelyNonperforming from '../pages/shop/report/LikelyNonperforming';
import AccountantReport from '../pages/shop/report/AccountantReport';
import FinyRequests from '../pages/finyRequest/Requests';
import FinyRequestDetail from '../pages/finyRequest/RequestDetail';

const authProtectedRoutes = [
  {path: Routes.dashboard, component: Dashboard},
  {path: Routes.otp, component: Otp},
  {path: Routes.notifications, component: Notifications},
  {path: Routes.payments, component: Payments},
  {path: Routes.shops, component: Companies},
  {path: Routes.shopDetail, component: CompanyDetail},
  //setting
  {path: Routes.finyAdmins, component: FinyAdmins},
  {path: Routes.finyFaq, component: FinyFAQ},
  {path: Routes.finyAuditFaq, component: AuditFAQ},
  {path: Routes.finyInfo, component: FinyInfo},
  //finy requests
  {path: Routes.finyRequests, component: FinyRequests},
  {path: Routes.finyRequestDetail, component: FinyRequestDetail},
  //auditors
  {path: Routes.auditCompanies, component: AuditAdmins},
  //farmers
  {path: Routes.farmers, component: Farmers},
  {path: Routes.farmerDetail, component: FarmerDetail},
  //certificate
  {path: Routes.certificates, component: CertificateList},
  {path: Routes.certificateDetail, component: CertificateDetail},
  //finance institudes
  {path: Routes.financeInstitutes, component: FinancialInstitute},
  //loan
  {path: Routes.loans, component: LoanList},
  {path: Routes.loanDetail, component: LoanDetail},
  {path: Routes.loanCreate, component: LoanDetail},
  {path: Routes.loanRequests, component: LoanRequests},
  {path: Routes.loanRequestDetail, component: LoanRequestDetail},
  //shop loan
  {path: Routes.shopClients, component: Clients},
  {path: Routes.shopLoanAccounts, component: ShopLoanAccounts},
  {path: Routes.shopRepayment, component: ShopLoanRepayment},
  {path: Routes.shopLoans, component: ShopLoans},
  {path: Routes.shopTotalLoans, component: ShopTotalLoan},
  {path: Routes.shopDueLoans, component: DueLoans},
  {path: Routes.shopNonperforming, component: NonperformingLoans},
  {path: Routes.shopOverdueLoans, component: OverdueLoans},
  {path: Routes.shopReport, component: ShopLoanReport},
  {path: Routes.shopLikelyNonperforming, component: LikelyNonperforming},
  {path: Routes.shopAccountant, component: AccountantReport},
];

const initialRoute = [{path: '*', component: NotFound}];
const publicRoutes = [{path: Routes.login, component: Login}];

const adminRoutes = [
  Routes.dashboard,
  Routes.notifications,
  Routes.otp,
  Routes.payments,
  Routes.shops,
  Routes.shopDetail,
  Routes.finyAdmins,
  Routes.finyFaq,
  Routes.finyAuditFaq,
  Routes.finyInfo,
  Routes.finyRequests,
  Routes.finyRequestDetail,
  Routes.auditCompanies,
  Routes.farmers,
  Routes.farmerDetail,
  Routes.certificates,
  Routes.certificateDetail,
  Routes.financeInstitutes,
  Routes.loans,
  Routes.loanCreate,
  Routes.loanDetail,
  Routes.loanRequests,
  Routes.loanRequestDetail,
];
const getAdminRoutes = () =>
  authProtectedRoutes.filter((r) => adminRoutes.includes(r.path));

const instituteRoutes = [
  Routes.dashboard,
  Routes.loanRequests,
  Routes.loanRequestDetail,
  // Routes.farmers,
  Routes.farmerDetail,
];
const getInstituteRoutes = () =>
  authProtectedRoutes.filter((r) => instituteRoutes.includes(r.path));

const shopRoutes = [
  Routes.dashboard,
  Routes.shopClients,
  Routes.shopLoanAccounts,
  Routes.shopRepayment,
  Routes.shopLoans,
  Routes.shopTotalLoans,
  Routes.shopDueLoans,
  Routes.shopNonperforming,
  Routes.shopOverdueLoans,
  Routes.shopReport,
  Routes.shopLikelyNonperforming,
  Routes.shopAccountant,
];

const getShopRoutes = () =>
  authProtectedRoutes.filter((r) => shopRoutes.includes(r.path));

export {
  publicRoutes,
  initialRoute,
  getAdminRoutes,
  getInstituteRoutes,
  getShopRoutes,
};
