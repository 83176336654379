import {EditOutlined, MenuOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {Routes} from '../../routes/route_helper';
import {CertificateModel} from '../../types';
import {useState} from 'react';
import CertificateEditModal from './EditModal';
import {tableAddbuttons} from '../../helpers/theme_helper';

const CertificateList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const [selected, setSelected] = useState<string | undefined>();
  const [certificates, certificatesLoading] = useCollection(
    'certificates',
    'createdAt',
  );

  const columns: ColumnsType<CertificateModel> = [
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
    },
    {
      key: 'desc',
      title: 'Тайлбар',
      dataIndex: 'desc',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (uid) => (
        <div className="flex flex-wrap gap-2">
          <Button
            onClick={() => {
              onClickDetail(uid);
            }}
            icon={<EditOutlined className="text-sm" />}
            size="small"
            type="primary"
          />
          <Button
            onClick={() => {
              onClickRow(uid);
            }}
            icon={<MenuOutlined className="text-sm" />}
            size="small"
          />
        </div>
      ),
    },
  ];

  const onClickRow = (uid: string) => {
    navigate(Routes.compilePath(Routes.certificateDetail, {id: uid}));
  };

  const onClickDetail = (uid: string) => {
    setSelected(uid);
    setOpen(true);
  };

  const onClickAdd = () => {
    setSelected(undefined);
    setOpen(true);
  };

  return (
    <div>
      <DataTable
        tableName="Гэрчилгээнүүд"
        columns={columns}
        data={certificates}
        loading={certificatesLoading}
        headerWidgers={tableAddbuttons('Гэрчилгээ нэмэх', onClickAdd)}
      />
      <CertificateEditModal
        open={open}
        toggle={toggleModal}
        selected={selected}
      />
    </div>
  );
};

export default CertificateList;
