import {ColumnsType} from 'antd/es/table';
import {useCollection} from '../../helpers/firestore';
import {OrderModel, PaymentStatus} from '../../types';
import {
  convertDateTimeFromTimestamp,
  formatCurrency,
} from '../../helpers/utils';
import {Timestamp} from 'firebase/firestore';
import DataTable from '../../components/table/Index';
import {Input, Tag} from 'antd';
import {useEffect, useState} from 'react';

const Payments = () => {
  const [user, setUser] = useState('');
  const [showData, setShowData] = useState<any[]>([]);
  const [orders, ordersLoading] = useCollection('orders', 'verifiedAt');

  const columns: ColumnsType<OrderModel> = [
    // {
    //   key: 'qpayInvoiceId',
    //   title: 'Нэхэмжлэлийн дугаар',
    //   dataIndex: 'qpayInvoiceId',
    // },
    {
      key: 'userPhone',
      title: 'Хэрэглэгч',
      dataIndex: 'userPhone',
    },
    {
      key: 'paymentType',
      title: 'Төлбөрийн хэрэгсэл',
      dataIndex: 'paymentType',
    },
    {
      key: 'amount',
      title: 'Үнийн дүн',
      dataIndex: 'amount',
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => (
        <div>
          {status === PaymentStatus.SUCCESS ? (
            <Tag color="success">Амжилттай</Tag>
          ) : (
            <Tag color="error">Амжилтгүй</Tag>
          )}
        </div>
      ),
    },
    {
      key: 'message',
      title: 'Тайлбар',
      dataIndex: 'message',
    },
    {
      key: 'verifiedAt',
      title: 'Огноо',
      dataIndex: 'verifiedAt',
      render: (verifiedAt: Timestamp) =>
        convertDateTimeFromTimestamp(verifiedAt),
    },
  ];

  const search = () => {
    setShowData(
      (orders as OrderModel[]).filter((o) => o.userPhone.includes(user)),
    );
  };

  useEffect(() => {
    setShowData(orders);
  }, [orders]);

  return (
    <DataTable
      tableName="Төлбөрийн жагсаалт"
      columns={columns}
      data={showData}
      loading={ordersLoading}
      onSearch={search}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Хэрэглэгч"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />,
      ]}
    />
  );
};

export default Payments;
