import {Button, Col, Row, Spin, Tabs} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import * as _ from 'lodash';
import {useEffect, useState} from 'react';
import {useDoc, useCollection} from '../../helpers/firestore';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {CertificateModel} from '../../types';
import FormGroup from './FormGroup';
import FormQuestion from './FromQuestion';

const CertificateDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [docUid, setDocUid] = useState<string | undefined>('');
  const [certData, certLoading] = useDoc(`certificates/${id ?? 0}`);
  const [certQuestionsData] = useCollection(
    `certificates/${id ?? 0}/form`,
    'createdAt',
  );
  const [certApplicationData] = useCollection(
    `certificates/${id ?? 0}/applicationForm`,
    'createdAt',
  );

  const goBack = () => navigate(Routes.certificates);

  useEffect(() => {
    setDocUid(id);
  }, [id]);

  return (
    <div className="pb-16">
      <div className="flex items-center gap-x-4 mb-3">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          {(certData as CertificateModel).name}
        </Button>
      </div>
      <hr />
      <div className="card-container p-6 my-8">
        <Tabs
          defaultActiveKey="1"
          tabPosition={'top'}
          items={[
            {
              label: `Өргөдөл`,
              key: '1',
              children: (
                <Spin size="small" spinning={certLoading && !_.isEmpty(id)}>
                  <div className="py-5">
                    <Row gutter={[16, 16]} className="pb-10">
                      <Col xs={24} lg={12}>
                        <div className="mb-6 font-medium text-info">
                          Асуулгын бүлгүүд
                        </div>
                        <FormGroup
                          docUid={docUid}
                          docName="certificates"
                          fieldName="applicationGroups"
                          certFormGroups={
                            (certData as CertificateModel).applicationGroups ??
                            []
                          }
                        />
                      </Col>
                    </Row>
                    <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
                    <FormQuestion
                      docUid={docUid}
                      subColName="applicationForm"
                      docName="certificates"
                      groups={
                        (certData as CertificateModel).applicationGroups ?? []
                      }
                      certFormQuestions={certApplicationData}
                    />
                  </div>
                </Spin>
              ),
            },
            {
              label: `Өөрийгөө үнэлэх хуудас`,
              key: '2',
              disabled: !(certData as CertificateModel).selfEvaluation,
              children: (
                <Spin size="small" spinning={certLoading && !_.isEmpty(id)}>
                  <div className="py-5">
                    <Row gutter={[16, 16]} className="pb-10">
                      <Col xs={24} lg={12}>
                        <div className="mb-6 font-medium text-info">
                          Асуулгын бүлгүүд
                        </div>
                        <FormGroup
                          docUid={docUid}
                          docName="certificates"
                          fieldName="groups"
                          certFormGroups={
                            (certData as CertificateModel).groups ?? []
                          }
                        />
                      </Col>
                    </Row>
                    <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
                    <FormQuestion
                      docUid={docUid}
                      subColName="form"
                      docName="certificates"
                      groups={(certData as CertificateModel).groups ?? []}
                      certFormQuestions={certQuestionsData}
                    />
                  </div>
                </Spin>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CertificateDetail;
