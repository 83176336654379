import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {Button, Col, Form, message, Row, Spin, Switch} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import {Input, InputNumber, Select} from 'antd';
import {
  getUidFirebase,
  saveData,
  useCollection,
  useDoc,
} from '../../helpers/firestore';
import {InstituteModel} from '../../types';
import {useEffect, useState} from 'react';
import * as _ from 'lodash';

const {Option} = Select;

const LoanDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [institutes] = useCollection('institutes', 'createdAt');
  const [certificates] = useCollection('certificates', undefined, {
    field: 'selfEvaluation',
    op: '==',
    value: true,
  });
  const [loanData, loanLoading] = useDoc(`loans/${id ?? 0}`);

  const goBack = () => navigate(Routes.loans);

  const onFinish = (values: any) => {
    const instituteUid = values.instituteUid;
    const institute = institutes.find((i: any) => i.uid === instituteUid);
    setLoading(true);
    saveLoanData(values, institute);
  };

  const saveLoanData = async (values: any, institute: any) => {
    let dataId = id;
    let isCreate = false;
    if (_.isEmpty(id)) {
      dataId = await getUidFirebase();
      isCreate = true;
    }
    try {
      await saveData(
        'loans',
        dataId,
        {
          ...values,
          desc: values.desc || '',
          institute,
          instituteUid: institute.uid,
          certificateUids: values.certificateUids || [],
          partnerDocuments: values.partnerDocuments || [],
          requirements: values.requirements || [],
          documents: values.documents || [],
        },
        isCreate,
      );
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: 'Зээлийн мэдээлэл хадгалагдлаа.',
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: 'Алдаа гарлаа',
      });
    }
  };

  useEffect(() => {
    if (!_.isEmpty(loanData)) {
      form.setFieldsValue({...loanData});
    }
  }, [loanData]);

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Зээлийн мэдээлэл
        </Button>
      </div>
      <hr />
      <Spin size="small" spinning={loanLoading && !_.isEmpty(id)}>
        <div className="card-container p-6 my-8">
          <Form form={form} onFinish={onFinish} scrollToFirstError>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <div className="my-2 font-medium">Нэр</div>
                <Form.Item
                  rules={[{required: true, message: 'Нэр оруулна уу'}]}
                  name="name"
                >
                  <Input />
                </Form.Item>
                <div className="my-2 font-medium">ББС сонгох</div>
                <Form.Item
                  rules={[{required: true, message: 'ББС оруулна уу'}]}
                  name="instituteUid"
                >
                  <Select>
                    {institutes.map((i: InstituteModel) => (
                      <Option key={i.uid} value={i.uid}>
                        {i.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className="my-2 font-medium">Тайлбар</div>
                <Form.Item name="desc">
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <div className="my-2 font-medium">Зээлийн хэмжээ</div>
                <div className="flex">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Зээлийн доод хэмжээ оруулна уу',
                      },
                    ]}
                    name="min"
                  >
                    <InputNumber
                      className="w-full"
                      addonAfter={<span>сая</span>}
                    />
                  </Form.Item>
                  <div className="mx-3 mt-2">-</div>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Зээлийн дээд хэмжээ оруулна уу',
                      },
                    ]}
                    name="limit"
                  >
                    <InputNumber
                      className="w-full"
                      addonAfter={<span>сая</span>}
                    />
                  </Form.Item>
                </div>
                <Row gutter={16}>
                  <Col xs={12}>
                    <div className="my-1 font-medium">Хугацаа</div>
                    <Form.Item
                      rules={[{required: true, message: 'Хугацаа оруулна уу'}]}
                      name="durationMonth"
                    >
                      <InputNumber
                        className="w-full"
                        addonAfter={<span>сар</span>}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <div className="my-1 font-medium">Хүү /төгрөг/</div>
                    <Form.Item
                      rules={[{required: true, message: 'Хүү оруулна уу'}]}
                      name="interest"
                    >
                      <InputNumber
                        className="w-full"
                        addonAfter={<span>%</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="font-medium">Идэвхитэй эсэх</div>
                <Form.Item
                  name="active"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <div className="bg-surface my-8 h-[1px]"></div>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className="my-2 font-medium">
                  Талбайн түүх баталгаажуулалт
                </div>
                <Form.Item
                  name="fieldVerification"
                  rules={[
                    {
                      required: true,
                      message: 'Талбайн түүх баталгаажуулалт оруулна уу',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Талбайн түүх"
                    options={Array.from(
                      {length: 5},
                      (_, i) => new Date().getFullYear() - i - 1,
                    ).map((c) => ({
                      label: c,
                      value: c,
                    }))}
                  />
                </Form.Item>
                <div className="my-2 font-medium">Шаардлагатай гэрчилгээ</div>
                <Form.Item
                  rules={[{required: true, message: 'Гэрчилгээ оруулна уу!'}]}
                  name="certificateUids"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Гэрчилгээ"
                    options={(certificates as any[]).map((c) => ({
                      label: c.name,
                      value: c.uid,
                    }))}
                  />
                </Form.Item>
                <div className="my-2 font-medium">Шаардлага</div>
                <Form.List name="requirements">
                  {(fields, {add, remove}, {errors}) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item required={false} key={field.key}>
                          <Form.Item {...field} noStyle>
                            <Input
                              className="w-full"
                              addonAfter={
                                <MinusCircleOutlined
                                  className="mx-2"
                                  onClick={() => remove(field.name)}
                                />
                              }
                            />
                          </Form.Item>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          className="w-full"
                          icon={<PlusOutlined />}
                        >
                          Нэмэх
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col xs={24} sm={12}>
                <div className="my-2 font-medium">Бүрдүүлэх материал</div>
                <Form.List name="documents">
                  {(fields, {add, remove}, {errors}) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item required={false} key={field.key}>
                          <Form.Item {...field} noStyle>
                            <Input
                              className="w-full"
                              addonAfter={
                                <MinusCircleOutlined
                                  className="mx-2"
                                  onClick={() => remove(field.name)}
                                />
                              }
                            />
                          </Form.Item>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          className="w-full"
                          icon={<PlusOutlined />}
                        >
                          Нэмэх
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div className="my-2 font-medium">
                  Хамтран зээлдэгчийн бүрдүүлэх материал
                </div>
                <Form.List name="partnerDocuments">
                  {(fields, {add, remove}, {errors}) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item required={false} key={field.key}>
                          <Form.Item {...field} noStyle>
                            <Input
                              className="w-full"
                              addonAfter={
                                <MinusCircleOutlined
                                  className="mx-2"
                                  onClick={() => remove(field.name)}
                                />
                              }
                            />
                          </Form.Item>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          className="w-full"
                          icon={<PlusOutlined />}
                        >
                          Нэмэх
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item className="mt-8 text-center">
                  <Button
                    type="primary"
                    className="w-full sm:w-48"
                    shape="round"
                    htmlType="submit"
                    disabled={loading || loanLoading}
                    loading={loading}
                  >
                    Хадгалах
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default LoanDetail;
