import DataTable from '../../components/table/Index';
import {useDoc} from '../../helpers/firestore';
import type {ColumnsType} from 'antd/es/table';
import {useEffect, useState} from 'react';
import {FaqModel} from '../../types';
import {Input} from 'antd';
import * as _ from 'lodash';
import FAQModal from './FaqEdit';
import {tableAddbuttons} from '../../helpers/theme_helper';

const AuditFAQ = () => {
  const [faq, faqLoading] = useDoc('staticData/auditFaq');
  const [faqs, setFaqs] = useState(Array<FaqModel>);
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<FaqModel> = [
    {
      key: 'groupName',
      title: 'Групп',
      dataIndex: 'groupName',
    },
    {
      key: 'question',
      title: 'Асуулт',
      dataIndex: 'question',
    },
    {
      key: 'answer',
      title: 'Хариулт',
      dataIndex: 'answer',
    },
  ];

  const onClickRow = (d: any) => {
    setSelected(d);
    toggleModal();
  };

  const showAddModal = () => {
    setSelected(undefined);
    toggleModal();
  };

  useEffect(() => {
    if (!_.isEmpty(faq)) {
      setFaqs((faq as any).lists as Array<FaqModel>);
    }
  }, [faq]);

  return (
    <div>
      <DataTable
        columns={columns}
        data={faqs}
        onClickRow={onClickRow}
        loading={faqLoading}
        headerWidgers={tableAddbuttons('Асуулт хариулт нэмэх', showAddModal)}
        filter={[
          <Input
            key="name"
            size="small"
            className="w-auto"
            placeholder="Нэрээр хайх"
          />,
        ]}
      />
      <FAQModal
        faqs={faqs}
        data={selected}
        open={open}
        toggle={toggleModal}
        docName="auditFaq"
      />
    </div>
  );
};

export default AuditFAQ;
