import {useContext} from 'react';
import {UserContext} from '../context/app.contexts';
import * as _ from 'lodash';
import {isAllowedRole} from '../constants/app_data';
import LogoImage from '../assets/images/logo.png';
import {Button} from 'antd';
import {logout} from '../helpers/firebase_auth';

const NotFound = () => {
  const {user, userRole} = useContext(UserContext);
  return (
    <div className="h-full flex items-center justify-center">
      {!_.isEmpty(user) && !isAllowedRole(userRole) && (
        <div className="card-container p-7 flex flex-col justify-center items-center">
          <div className="flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="flex items-center gap-3">
            <i className="bx bx-user"></i>
            <div>{user.email}</div>
          </div>
          <div className="my-4 text-error">
            Та энэхүү хаягаар системд хандах эрхгүй байна.
          </div>
          <Button danger shape="round" onClick={logout}>
            Гарах
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotFound;
