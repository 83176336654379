import React, {useState, useEffect} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import * as _ from 'lodash';

import {
  LoadingContext,
  RouteContext,
  UserContext,
} from './context/app.contexts';
import Router from './routes/Router';
import {auth} from './firebase';
import {AuthRoles, RouteModel} from './types';
import './assets/styles/index.scss';
import {
  getAdminRoutes,
  getInstituteRoutes,
  getShopRoutes,
  initialRoute,
} from './routes/routes';

const App = () => {
  const [user, loading] = useAuthState(auth);
  const [userRole, setUserRole] = useState('');
  const [userRoutes, setUserRoutes] = useState<null | RouteModel[]>(
    initialRoute,
  );
  const [loadingPage, setLoadingPage] = useState(true);

  const documentHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  window.addEventListener('resize', documentHeight);
  documentHeight();

  useEffect(() => {
    if (!loading && !user) {
      setUserRole(AuthRoles.ANONYMOUS);
    } else if (!loading && user) {
      user.getIdTokenResult().then((token) => {
        setUserRole(_.get(token, 'claims.role', AuthRoles.ANONYMOUS));
      });
    }
  }, [user, loading]);

  useEffect(() => {
    if (userRole === AuthRoles.ADMIN) {
      setUserRoutes(getAdminRoutes());
    } else if (userRole === AuthRoles.FINANCIAL_INSTITUTE) {
      setUserRoutes(getInstituteRoutes());
    } else if (userRole === AuthRoles.SHOP) {
      setUserRoutes(getShopRoutes());
    }
    if (!_.isEmpty(userRole)) {
      setLoadingPage(false);
    }
  }, [userRole]);

  return (
    <React.Fragment>
      <RouteContext.Provider value={{authProtectedRoutes: userRoutes}}>
        <UserContext.Provider value={{user, loading, userRole}}>
          <LoadingContext.Provider value={loadingPage}>
            <Router />
          </LoadingContext.Provider>
        </UserContext.Provider>
      </RouteContext.Provider>
    </React.Fragment>
  );
};

export default App;
