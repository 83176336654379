import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Row, Spin, Tag} from 'antd';
import {Routes} from '../../routes/route_helper';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {
  emptyText,
  getCertificateModalChild,
  getFieldModalChild,
  requestTag,
} from '../../helpers/theme_helper';
import {RequestStatus} from '../../types';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import RequestFormModal from './RequestFormModal';
import DataTable from '../../components/table/Index';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {firestore} from '../../firebase';
import {useDoc} from '../../helpers/firestore';
import * as _ from 'lodash';

const FinyRequestDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [user, userLoading] = useDoc(`users/${id}`);
  const [requests, setRequests] = useState<any[]>([]);
  const [loadingOthers, setLoadingOthers] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = (value: boolean) => setOpenModal(value);
  const [modalChild, setModalChild] = useState<JSX.Element>(<></>);

  const goBack = () => navigate(Routes.finyRequests);

  const columns: ColumnsType<any> = [
    {
      key: 'verifyItem',
      title: 'Хүсэлт',
      dataIndex: 'verifyItem',
      render: (verifyItem: string) => <Tag>{verifyItem}</Tag>,
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => requestTag(status),
    },
    {
      key: 'date',
      title: 'Огноо',
      dataIndex: 'date',
      render: (date: Timestamp) => convertDateTimeFromTimestamp(date),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <Button
          size="small"
          type="link"
          onClick={() => {
            showDetail(record, record.type);
          }}
        >
          Харах
        </Button>
      ),
    },
  ];

  const getRequests = async () => {
    setLoadingOthers(true);
    const fieldPromise = firestore
      .collection(`fieldRequests`)
      .where('status', 'in', [RequestStatus.sent, RequestStatus.verified])
      .where('userUid', '==', id)
      .orderBy('sentAt', 'desc');
    const certPromise = firestore
      .collection(`certificateRequests`)
      .where('status', 'in', [RequestStatus.sent, RequestStatus.verified])
      .where('userUid', '==', id)
      .orderBy('sentAt', 'desc');
    const data = await Promise.all([fieldPromise.get(), certPromise.get()]);
    const fieldRequests = data[0].docs.map((d) => ({...d.data(), uid: d.id}));
    const certificateRequests = data[1].docs.map((d) => ({
      ...d.data(),
      uid: d.id,
    }));
    const field = (fieldRequests as any[]).map((l) => ({
      ...l,
      date: l.sentAt,
      verifyItem: `Талбайн түүх - ${l.year}`,
      type: 'field',
    }));
    const certificate = (certificateRequests as any[]).map((c) => ({
      ...c,
      date: c.sentAt,
      verifyItem: c.isContract
        ? `Амлалт үүргийн гэрээ - ${c.certificateName}`
        : `Гэрчилгээ баталгаажуулах - ${c.certificateName}`,
      type: 'certificate',
    }));
    const allRequests = [...field, ...certificate];
    const sorted = allRequests.sort(function (x, y) {
      return y.date - x.date;
    });
    setRequests(sorted);
    setLoadingOthers(false);
  };

  const showDetail = (request: any, type: string) => {
    setModalChild(
      type === 'field'
        ? getFieldModalChild(request)
        : getCertificateModalChild(request),
    );
    toggleModal(true);
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <div className="pb-16">
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Фини хүсэлт
        </Button>
      </div>
      <hr />
      <Spin spinning={loadingOthers || userLoading} size="small">
        <Row gutter={[16, 16]} className="my-8">
          <Col xs={24} md={12}>
            <div className="card-container p-6 h-full">
              <div className="text-lg font-medium">Хэрэглэгчийн мэдээлэл</div>
              <div className="mt-5 text-sm text-gray">Хэрэглэгч</div>
              <div className="mt-1">
                {user.userType === 'citizen' ? 'Иргэн' : 'Компани'}
              </div>
              <div className="mt-5 text-sm text-gray">Овог, нэр</div>
              <div className="mt-1">
                {user.lastname} {user.firstname}
              </div>
              {user.userType === 'company' && (
                <>
                  <div className="mt-5 text-sm text-gray">Компаний нэр</div>
                  <div className="mt-1">{user.companyName}</div>
                </>
              )}
              <div className="mt-5 text-sm text-gray">Регистр </div>
              <div className="mt-1">{user.registerNumber || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
              <div className="mt-1">{user.phone || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Аймаг, сум</div>
              <div className="mt-1">
                {[user.city, user.district]
                  .filter((u) => !_.isEmpty(u))
                  .join(', ')}
              </div>
              <div className="mt-5 text-sm text-gray">НТ дугаар</div>
              <div className="mt-1">{user.ntDugaar || emptyText}</div>
            </div>
          </Col>
        </Row>
      </Spin>
      <RequestFormModal
        open={openModal}
        toggle={toggleModal}
        children={modalChild}
      />
      <DataTable
        tableName={`Хүсэлтүүд`}
        columns={columns}
        data={requests}
        loading={loadingOthers}
        reloadData={getRequests}
      />
    </div>
  );
};

export default FinyRequestDetail;
