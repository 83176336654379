import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Form,
  message,
  Input,
  Modal,
  Select,
  InputRef,
  Divider,
} from 'antd';
import * as _ from 'lodash';
import {FaqModel} from '../../types';
import {updateData} from '../../helpers/firestore';
import {PlusOutlined} from '@ant-design/icons';
const {Option} = Select;

type Props = {
  docName: string;
  open?: boolean;
  toggle: (value: boolean) => void;
  data?: FaqModel;
  faqs?: Array<FaqModel>;
};

const FAQEdit: React.FC<Props> = ({
  data,
  faqs,
  open = false,
  toggle,
  docName,
}) => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState(Array<string>);
  const [addType, setAddType] = useState('');

  const onCancel = () => {
    toggle(false);
    setLoading(false);
  };

  const onSave = async (values: any) => {
    setLoading(true);
    await saveFAQData(values);
    setLoading(false);
  };

  const saveFAQData = async (values: any) => {
    if (_.isEmpty(data)) {
      faqs?.push({...values, uid: faqs.length});
      await updateData(`staticData/${docName}`, {lists: faqs});
    } else {
      const newData = faqs?.map((q) => (q.uid === data.uid ? values : q));
      await updateData(`staticData/${docName}`, {lists: newData});
    }
    onCancel();
    form.resetFields();
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа.',
    });
  };

  const deleteItem = async () => {
    setLoading(true);
    if (!_.isEmpty(data)) {
      const newData = faqs?.filter((f) => f.uid !== data.uid);
      await updateData(`staticData/${docName}`, {lists: newData});
    }
    onCancel();
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл устгагдлаа.',
    });
  };

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddType(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    if (_.isEmpty(addType)) return;
    setTypes([...types, addType]);
    setAddType('');
  };

  useEffect(() => {
    setTypes(_.uniqBy(faqs, 'groupName').map((g) => g.groupName));
  }, [faqs]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data]);

  return (
    <>
      {contextHolder}
      <Modal
        forceRender
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal px-6"
        centered
        footer={[
          <div key={1} className="flex">
            <Button
              className="w-full"
              shape="round"
              danger
              onClick={deleteItem}
              disabled={loading || _.isEmpty(data)}
            >
              Устгах
            </Button>
            <Button
              type="primary"
              className="w-full"
              shape="round"
              onClick={() => {
                form.submit();
              }}
              disabled={loading}
              loading={loading}
            >
              Хадгалах
            </Button>
          </div>,
        ]}
      >
        <div className="px-5">
          <div className="bg-surface my-8 h-[1px]"></div>
          <Form form={form} onFinish={onSave} scrollToFirstError>
            <div className="my-1">Асуулт</div>
            <Form.Item rules={[{required: true}]} name="question">
              <Input className="w-full" />
            </Form.Item>
            <div className="my-1">Хариулт</div>
            <Form.Item rules={[{required: true}]} name="answer">
              <Input className="w-full" />
            </Form.Item>
            <div className="my-1">Групп</div>
            <Form.Item rules={[{required: true}]} name="groupName">
              <Select
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider className="my-3 mx-2" />
                    <div className="px-2 flex">
                      <Input
                        placeholder="Төрөл"
                        ref={inputRef}
                        value={addType}
                        onChange={onTypeChange}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItem}
                      >
                        Төрөл нэмэх
                      </Button>
                    </div>
                  </>
                )}
              >
                {types.map((o: string) => (
                  <Option key={o} value={o}>
                    {o}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default FAQEdit;
