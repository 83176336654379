import React, {useState} from 'react';
import {Button, Col, Form, message, Input, Row, Modal, Upload} from 'antd';
import * as _ from 'lodash';

import phone_icon from '../../assets/images/icons/phone.svg';
import fb_icon from '../../assets/images/icons/fb.svg';
import pin_icon from '../../assets/images/icons/pin.png';
import {uploadImageBlob} from '../../helpers/firebase_storage';
import TextArea from 'antd/es/input/TextArea';
import {createAppUser} from '../../helpers/firebase_functions';
import {AuthRoles} from '../../types';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
};

const ShopAdd: React.FC<Props> = ({open = false, toggle}) => {
  const [form] = Form.useForm();
  const [image, setImage] = useState<string | Blob>();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    toggle(false);
    setImage(undefined);
    form.resetFields();
  };

  const validate = (values: any) => {
    if (
      _.isEmpty(values.name) ||
      _.isEmpty(values.phone) ||
      _.isEmpty(values.address) ||
      _.isEmpty(values.shortDesc)
    )
      return false;
    return true;
  };

  const onSave = async (values: any) => {
    form.validateFields();
    if (!validate(values)) return;
    setLoading(true);
    if (image instanceof Blob) {
      const url = await handleUpload(image as Blob);
      const result = await createAppUser(values.email, AuthRoles.SHOP, {
        ...values,
        image: url,
      });
      setLoading(false);
      toggle(false);
      if (!result.status) {
        messageApi.open({
          type: 'error',
          content: result.msg || 'Алдаа гарлаа',
        });
      } else {
        onCancel();
        messageApi.open({
          type: 'success',
          content: 'Дэлгүүрийн мэдээлэл хадгалагдлаа.',
        });
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Зураг сонгоно уу',
      });
      setLoading(false);
    }
  };

  const handleUpload = async (file: Blob) => {
    try {
      const result = await uploadImageBlob(file, 'images/shops');
      return result as string;
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: e ?? 'Зураг upload хийхэд алдаа гарлаа',
      });
      return '';
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal px-6"
        centered
        footer={[
          <Button key={1} shape="round" onClick={onCancel} disabled={loading}>
            Болих
          </Button>,
          <Button
            key={2}
            type="primary"
            shape="round"
            disabled={loading}
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="px-5">
          <div className="bg-surface my-8 h-[1px]"></div>
          <Form form={form} onFinish={onSave} scrollToFirstError>
            <Row gutter={[16, 8]}>
              <Col xs={24}>
                <Upload
                  name={`companyImage`}
                  listType="picture-card"
                  className="w-full mb-4"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    setImage(file);
                    return false;
                  }}
                >
                  {_.isEmpty(image) ? (
                    <div className="w-full">Cover Зураг</div>
                  ) : (
                    <img
                      className="w-full h-full object-cover rounded-sm"
                      src={URL.createObjectURL(image as Blob)}
                    />
                  )}
                </Upload>
              </Col>
              <Col xs={24}>
                <div>Нэвтрэх имейл хаяг</div>
                <Form.Item
                  rules={[{required: true, message: 'Имейл хаяг оруулна уу'}]}
                  name="email"
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div>Нэр</div>
                <Form.Item
                  rules={[
                    {required: true, message: 'Дэлгүүрийн нэр оруулна уу'},
                  ]}
                  name="name"
                >
                  <Input className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <div>Утас</div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Дэлгүүрийн утасны дугаар оруулна уу',
                    },
                  ]}
                  name="phone"
                >
                  <Input
                    className="w-full"
                    addonAfter={<img width="14px" src={phone_icon} />}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <div>Фэйсбүүк хаяг</div>
                <Form.Item name="fb">
                  <Input
                    className="w-full"
                    addonAfter={<img height="15px" src={fb_icon} />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div>Хаяг байршил</div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Дэлгүүрийн хаяг, байршил оруулна уу',
                    },
                  ]}
                  name="address"
                >
                  <Input
                    className="w-full"
                    addonAfter={<img height="14px" src={pin_icon} />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div>Богино тайлбар</div>
                <Form.Item
                  rules={[{required: true, message: 'Тайлбар оруулна уу'}]}
                  name="shortDesc"
                >
                  <TextArea className="w-full" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ShopAdd;
