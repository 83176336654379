import React from 'react';
import {Button, Modal} from 'antd';
import * as _ from 'lodash';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  children: JSX.Element;
};

const RequestFormModal: React.FC<Props> = ({
  open = false,
  toggle,
  children,
}) => {
  const onCancel = () => {
    toggle(false);
  };

  return (
    <Modal
      open={open}
      title="Finy App"
      onCancel={onCancel}
      centered
      footer={[
        <Button
          key="send"
          className="rounded-xl"
          type="primary"
          onClick={onCancel}
        >
          Буцах
        </Button>,
      ]}
    >
      <div className="dialog-body">{children}</div>
    </Modal>
  );
};

export default RequestFormModal;
