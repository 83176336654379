import {useEffect, useState} from 'react';
import {updateData, useDoc} from '../../helpers/firestore';
import {Button, Col, Form, Input, Row, Spin, message} from 'antd';
import {CheckOutlined, CloseOutlined, EditOutlined} from '@ant-design/icons';

const FinyInfo = () => {
  const [form] = Form.useForm();
  const [isEdit, setEdit] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [contact, contactLoading] = useDoc('staticData/contact');
  const [loading, setLoading] = useState(false);

  const saveData = async (values: any) => {
    setLoading(true);
    await updateData('staticData/contact', values);
    setEdit(false);
    setLoading(false);
    messageApi.open({
      type: 'success',
      content: 'Холбогдох мэдээлэл засагдлаа',
    });
  };

  const onCancel = () => {
    form.setFieldsValue(contact);
    setEdit(false);
  };

  useEffect(() => {
    form.setFieldsValue(contact);
  }, [contact]);

  return (
    <div>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <div className="card-container px-5 py-7">
            <Spin spinning={contactLoading}>
              {!isEdit ? (
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-lg font-medium">
                      Холбоо барих мэдээлэл
                    </div>
                    <div>
                      <Button
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => setEdit(true)}
                      />
                    </div>
                  </div>
                  <div className="mt-5 text-sm text-gray">Имейл хаяг</div>
                  <div className="mt-1">{(contact as any).email}</div>
                  <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
                  <div className="mt-1">{(contact as any).phone}</div>
                  <div className="mt-5 text-sm text-gray">Веб хуудас</div>
                  <div className="mt-1">{(contact as any).web}</div>
                  <div className="mt-5 text-sm text-gray">
                    Фэйсбүүк хуудасны нэр
                  </div>
                  <div className="mt-1">{(contact as any).fb}</div>
                  <div className="mt-5 text-sm text-gray">
                    Фэйсбүүк хуудасны хаяг
                  </div>
                  <div className="mt-1">{(contact as any).fbUrl || '-'}</div>
                </div>
              ) : (
                <div>
                  <div className="flex justify-between items-center my-2">
                    <div className="text-lg font-medium">
                      Холбоо барих мэдээлэл
                    </div>
                    <div className="flex gap-x-3">
                      <Button
                        shape="circle"
                        icon={<CloseOutlined />}
                        disabled={loading}
                        onClick={onCancel}
                      />
                      <Button
                        shape="circle"
                        type="primary"
                        loading={loading}
                        disabled={loading}
                        icon={<CheckOutlined />}
                        onClick={() => {
                          form.submit();
                        }}
                      />
                    </div>
                  </div>
                  <Form form={form} onFinish={saveData} scrollToFirstError>
                    <div className="text-sm text-gray">Имейл хаяг</div>
                    <Form.Item rules={[{required: true}]} name="email">
                      <Input inputMode="email" />
                    </Form.Item>
                    <div className="text-sm text-gray">Утасны дугаар</div>
                    <Form.Item rules={[{required: true}]} name="phone">
                      <Input />
                    </Form.Item>
                    <div className="text-sm text-gray">Веб хуудас</div>
                    <Form.Item rules={[{required: true}]} name="web">
                      <Input inputMode="url" />
                    </Form.Item>
                    <div className="text-sm text-gray">
                      Фэйсбүүк хуудасны нэр
                    </div>
                    <Form.Item rules={[{required: true}]} name="fb">
                      <Input />
                    </Form.Item>
                    <div className="text-sm text-gray">
                      Фэйсбүүк хуудасны хаяг
                    </div>
                    <Form.Item rules={[{required: true}]} name="fbUrl">
                      <Input inputMode="url" />
                    </Form.Item>
                  </Form>
                </div>
              )}
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FinyInfo;
