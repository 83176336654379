import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import type {ColumnsType} from 'antd/es/table';
import {UserModel} from '../../types';
import {Timestamp} from '@firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {useNavigate} from 'react-router-dom';
import ShopAdd from './ShopAdd';
import {useState} from 'react';
import {Routes} from '../../routes/route_helper';
import {tableAddbuttons} from '../../helpers/theme_helper';

const Companies = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [company, companyLoading] = useCollection('shops', 'createdAt');
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<UserModel> = [
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
    },
    {
      key: 'email',
      title: 'Нэвтрэх имейл',
      dataIndex: 'email',
    },
    {
      key: 'phone',
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.shopDetail, {id: d.uid}));
  };

  const showAddModal = () => {
    toggleModal();
  };

  return (
    <div>
      <DataTable
        tableName="Дэлгүүрүүд"
        columns={columns}
        data={company}
        loading={companyLoading}
        onClickRow={onClickRow}
        headerWidgers={tableAddbuttons('Дэлгүүр нэмэх', showAddModal)}
      />
      <ShopAdd open={open} toggle={toggleModal} />
    </div>
  );
};

export default Companies;
