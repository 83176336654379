import React, {useEffect, useState} from 'react';
import {Button, Col, Input, InputNumber, message, Row, Select} from 'antd';
import {FormGroup, FormQuestion, QuestionOptions} from '../../types';
import {removeData, saveData, updateData} from '../../helpers/firestore';
import {formFieldTypes} from '../../constants/app_data';
import * as _ from 'lodash';

type Props = {
  docName: string;
  subColName: string;
  docUid?: string | undefined;
  certFormQuestions: FormQuestion[];
  groups: FormGroup[];
};

const FormQuestionComp: React.FC<Props> = ({
  certFormQuestions,
  groups,
  docUid,
  docName,
  subColName,
}) => {
  //Certificate questions
  const addQuestion = async () => {
    if (_.isEmpty(docUid)) return;
    await saveData(
      `${docName}/${docUid ?? 0}/${subColName}`,
      undefined,
      {},
      true,
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={24}>
        <div className="mb-4 font-medium text-info flex gap-2 justify-between items-center">
          Асуултууд ({certFormQuestions.length})
          <Button key="submit" size="small" shape="round" onClick={addQuestion}>
            Нэмэх
          </Button>
        </div>
      </Col>
      {(certFormQuestions as FormQuestion[]).map((q) => (
        <Col key={q.uid} xs={24} lg={12}>
          <Question
            subColName={subColName}
            certificateUid={docUid}
            formQuestion={q}
            groups={groups}
            docName={docName}
          />
        </Col>
      ))}
    </Row>
  );
};

type QuestionProps = {
  docName: string;
  subColName: string;
  certificateUid?: string | undefined;
  formQuestion: FormQuestion;
  groups: FormGroup[];
};

const Question: React.FC<QuestionProps> = ({
  groups,
  docName,
  subColName,
  formQuestion,
  certificateUid,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [question, setQuestion] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [infoText, setInfoDesc] = useState('');
  const [groupId, setGroupId] = useState<number | undefined>();
  const [type, setType] = useState('');
  const [options, setOptions] = useState([] as QuestionOptions[]);

  const validate = () => {
    return (
      (type !== 'textarea' && type !== 'plant_selection'
        ? !_.isEmpty(options)
        : true) &&
      !_.isEmpty(certificateUid) &&
      !_.isEmpty(question) &&
      !_.isEmpty(type) &&
      groupId != null
    );
  };

  const editQuestion = async () => {
    if (!validate()) return;
    if (_.isEmpty(certificateUid) || _.isEmpty(formQuestion.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Алдаа гарлаа',
      });
      return;
    }
    setLoading(true);
    await updateData(
      `${docName}/${certificateUid}/${subColName}/${formQuestion.uid}`,
      {
        question,
        placeholder,
        groupId,
        type,
        options,
        infoText,
      } as FormQuestion,
    );
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  const removeQuestion = async () => {
    if (_.isEmpty(certificateUid) || _.isEmpty(formQuestion.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Алдаа гарлаа',
      });
      return;
    }

    setLoading(true);
    await removeData(
      `${docName}/${certificateUid}/${subColName}/${formQuestion.uid}`,
    );
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  useEffect(() => {
    if (!_.isEmpty(formQuestion)) {
      setQuestion(formQuestion.question ?? '');
      setPlaceholder(formQuestion.placeholder ?? '');
      setInfoDesc(formQuestion.infoText ?? '');
      setGroupId(formQuestion.groupId);
      setType(formQuestion.type ?? '');
      setOptions(formQuestion.options ?? []);
    }
  }, [formQuestion]);

  return (
    <>
      {contextHolder}
      <div
        key={`cert-form-question-${formQuestion.uid}`}
        className="border border-surface rounded-sm p-4 border-solid bg-lightGray mb-3"
      >
        {expanded || _.isEmpty(formQuestion.question) ? (
          <>
            <div className="flex items-center justify-between mb-4 gap-3">
              <div className="font-medium">Асуулт</div>
              {!_.isEmpty(formQuestion.question) && (
                <div
                  className="cursor-pointer text-info text-sm"
                  onClick={() => setExpanded(false)}
                >
                  Хураах
                </div>
              )}
            </div>
            <Input.TextArea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Асуулт"
              className="mb-3"
            />
            <Input
              value={placeholder}
              onChange={(e) => setPlaceholder(e.target.value)}
              placeholder="Hint"
              className="mb-3"
            />
            <Input.TextArea
              value={infoText}
              onChange={(e) => setInfoDesc(e.target.value)}
              placeholder="Тайлбар"
              className="mb-3"
            />
            <div className="flex gap-3 mb-3">
              <Select
                value={groupId}
                onChange={(value) => setGroupId(value)}
                options={groups.map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
                placeholder="Бүлэг"
                className="flex-1 overflow-hidden"
              />
              <Select
                value={type}
                onChange={(value) => setType(value)}
                options={formFieldTypes.map((c) => ({
                  value: c.value,
                  label: c.label,
                }))}
                placeholder="Төрөл"
                className="flex-1"
              />
            </div>
            {type !== 'textarea' &&
              type !== 'plant_selection' &&
              !_.isEmpty(type) && (
                <div className="flex justify-between items-center mb-3">
                  <div className="font-medium">Сонголтууд</div>
                  <Button
                    key="save"
                    size="small"
                    shape="round"
                    onClick={() =>
                      setOptions([
                        ...options,
                        {id: options.length, value: '', score: 0},
                      ])
                    }
                  >
                    Сонголт нэмэх
                  </Button>
                </div>
              )}
            {options.map((o) => (
              <div className="flex gap-3 mb-3 w-full" key={`option-${o.id}`}>
                <Input
                  value={o.value}
                  onChange={(e) => {
                    setOptions(
                      options.map((item) =>
                        item.id === o.id ? {...o, value: e.target.value} : item,
                      ),
                    );
                  }}
                  placeholder="Утга"
                  className="flex-1 overflow-hidden"
                />
                <InputNumber
                  value={o.score}
                  onChange={(e) => {
                    setOptions(
                      options.map((item) =>
                        item.id === o.id ? {...o, score: e || 0} : item,
                      ),
                    );
                  }}
                  placeholder="Оноо"
                />
              </div>
            ))}
            <div className="flex justify-center gap-3">
              <Button
                key="delete"
                size="small"
                shape="round"
                loading={loading}
                onClick={removeQuestion}
                danger
              >
                Устгах
              </Button>
              <Button
                key="save"
                size="small"
                shape="round"
                type="primary"
                loading={loading}
                disabled={!validate()}
                onClick={editQuestion}
              >
                Хадгалах
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between items-center gap-3">
              <div>
                <div className="text-sm text-gray mb-1">
                  {groups.find((g) => g.id === groupId)?.name || ''}
                </div>
                <div>{question}</div>
              </div>
              <div
                className="cursor-pointer text-info text-sm"
                onClick={() => setExpanded(true)}
              >
                Засах
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FormQuestionComp;
