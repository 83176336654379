import {functions} from '../firebase';
import * as _ from 'lodash';
import {AuthRoles, AUDIT_ADMIN} from '../types';
import {isAllowedRole} from '../constants/app_data';

export const createAppUser = async (
  email: string,
  role: AuthRoles,
  userData: any,
) => {
  try {
    console.log('createAppUser starting', email, role);
    if (!isAllowedRole(role))
      return {status: false, msg: 'Role is not allowed'};
    const data = await functions.httpsCallable('createAppUser')({
      email,
      role,
      userData,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const addInstituteUser = async (
  email: string,
  instituteUid: string
) => {
  try {
    console.log('addInstituteUser starting', email);
    const data = await functions.httpsCallable('createInstituteUser')({
      email,
      role: AuthRoles.FINANCIAL_INSTITUTE,
      instituteUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const removeInstituteUser = async (
  email: string,
  instituteUid: string
) => {
  try {
    console.log('removeInstituteUser starting', email);
    const data = await functions.httpsCallable('removeInstituteUser')({
      email,
      instituteUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const createAuditAdmin = async (email: string, userData: any) => {
  try {
    const data = await functions.httpsCallable('createAppUser')({
      email,
      role: AUDIT_ADMIN,
      userData,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const sendNotification = async (notificationUid: string) => {
  try {
    const data = await functions.httpsCallable('sendNotification')({
      notificationUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const toggleFarmerActive = async (farmerUid: string) => {
  try {
    const data = await functions.httpsCallable('toggleFarmer')({
      farmerUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};
