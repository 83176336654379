import DataTable from '../../components/table/Index';
import type {ColumnsType} from 'antd/es/table';
import {UserModel} from '../../types';
import {Timestamp} from '@firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {Input} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';

const Farmers = () => {
  const navigate = useNavigate();
  const {userRole, user} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [farmers, setFarmers] = useState([]);
  const [showData, setShowData] = useState([]);
  const [phone, setPhone] = useState('');

  const columns: ColumnsType<UserModel> = [
    {
      key: 'phone',
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      key: 'registerNumber',
      title: 'Регистр',
      dataIndex: 'registerNumber',
    },
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
      render: (_, record) => (
        <div>
          {record.lastname} {record.firstname}
        </div>
      ),
    },
    {
      key: 'city',
      title: 'Хот/аймаг',
      dataIndex: 'city',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.farmerDetail, {id: d.uid}));
  };

  const getFarmersData = async () => {
    setLoading(true);
    let ref: any = firestore.collection('users');
    const farmerData = await ref.orderBy('createdAt', 'desc').get();
    setFarmers(farmerData.docs.map((d: any) => ({...d.data(), uid: d.id})));
    setShowData(farmerData.docs.map((d: any) => ({...d.data(), uid: d.id})));
    setLoading(false);
  };

  const search = () => {
    setShowData(farmers.filter((f: any) => f.phone.includes(phone)));
  };

  useEffect(() => {
    if (!_.isEmpty(userRole) && !_.isEmpty(user)) {
      getFarmersData();
    }
  }, [userRole, user]);

  return (
    <DataTable
      tableName="Тариаланчид"
      columns={columns}
      data={showData}
      loading={loading}
      onSearch={search}
      reloadData={getFarmersData}
      onClickRow={onClickRow}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Утасны дугаар"
          value={phone}
          onChange={(v) => setPhone(v.target.value)}
        />,
      ]}
    />
  );
};

export default Farmers;
