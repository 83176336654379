import {Menu, MenuProps} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {Routes} from '../../routes/route_helper';
import {AuthRoles} from '../../types';

type MenuItem = Required<MenuProps>['items'][number];
const adminMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },
  {
    name: 'Мэдэгдэл',
    path: Routes.notifications,
  },
  {
    name: 'Хэрэглэгчид',
    path: 'users',
    children: [
      {
        name: 'Тариаланчид',
        path: Routes.farmers,
      },
      {
        name: 'Аудитын компаниуд',
        path: Routes.auditCompanies,
      },
      {
        name: 'Дэлгүүр',
        path: Routes.shops,
      },
      {
        name: 'ББС',
        path: Routes.financeInstitutes,
      },
    ],
  },
  {
    name: 'Апп контент',
    path: 'content',
    children: [
      {
        name: 'Зээл',
        path: Routes.loans,
      },
      {
        name: 'Гэрчилгээ',
        path: Routes.certificates,
      },
    ],
  },
  {
    name: 'Хүсэлтүүд',
    path: 'requests',
    children: [
      {
        name: 'Зээлийн хүсэлт',
        path: Routes.loanRequests,
      },
      {
        name: 'Фини хүсэлт',
        path: Routes.finyRequests,
      },
    ],
  },
  {
    name: 'Төлбөр',
    path: Routes.payments,
  },
  {
    name: 'Тохиргоо',
    path: 'setting',
    children: [
      {
        name: 'OTP код',
        path: Routes.otp,
      },
      {
        name: 'Холбоо барих',
        path: Routes.finyInfo,
      },
      {
        name: 'Админ хэрэглэгч',
        path: Routes.finyAdmins,
      },
      {
        name: 'Түгээмэл асуулт',
        path: Routes.finyFaq,
      },
      {
        name: 'Аудит түгээмэл асуулт',
        path: Routes.finyAuditFaq,
      },
    ],
  },
];

const instituteMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },

  {
    name: 'Зээлийн хүсэлт',
    path: Routes.loanRequests,
  },
];

const shopMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },
  {
    name: 'Харилцагч',
    path: Routes.shopClients,
  },
  {
    name: 'Зээл',
    path: 'loan',
    children: [
      {
        name: 'Дансны жагсаалт зээл авсан хүмүүсийн жагсаалт',
        path: Routes.shopLoanAccounts,
      },
      {
        name: 'Нийт зээлийн дүн',
        path: Routes.shopLoans,
      },
      {
        name: 'Зээлийн эргэн төлөлтийн мэдээ',
        path: Routes.shopRepayment,
      },
    ],
  },
  {
    name: 'Тайлан',
    path: 'report',
    children: [
      {
        name: 'Нийт зээлийн хэмжээ',
        path: Routes.shopTotalLoans,
      },
      {
        name: 'Төлөлт дөхсөн зээлүүд',
        path: Routes.shopDueLoans,
      },
      {
        name: 'Чанаргүй зээлийн жагсаалт',
        path: Routes.shopNonperforming,
      },
      {
        name: 'Хугацаа хэтэрсэн зээл',
        path: Routes.shopOverdueLoans,
      },
      {
        name: 'Зээл олголтын тайлан',
        path: Routes.shopReport,
      },
      {
        name: 'Чанаргүй болох магадлалтай зээлийн жагсаалт',
        path: Routes.shopLikelyNonperforming,
      },
      {
        name: 'Тайлан харах',
        path: Routes.shopAccountant,
      },
    ],
  },
];

const SidebarContent = () => {
  const location = useLocation();
  const {userRole} = useContext(UserContext);
  const [menuItems, setMenuItems] = useState([] as MenuItem[]);
  const [rootSubmenuKeys, setRootSubMenuKeys] = useState([] as string[]);
  const [openKeys, setOpenKeys] = useState([] as string[]);
  const [openGroupKeys, setOpenGroupKeys] = useState([] as string[]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  useEffect(() => {
    let data = [] as any[];
    if (userRole === AuthRoles.ADMIN) {
      data = adminMenuData;
    } else if (userRole === AuthRoles.FINANCIAL_INSTITUTE) {
      data = instituteMenuData;
    } else if (userRole === AuthRoles.SHOP) {
      data = shopMenuData;
    }
    setMenuItems(
      data.map((m) =>
        getItem(
          m.children != null ? m.name : <Link to={m.path}>{m.name}</Link>,
          m.path,
          null,
          m.children?.map((c: any) =>
            getItem(<Link to={c.path}>{c.name}</Link>, c.path),
          ),
        ),
      ),
    );
    setRootSubMenuKeys(
      data.filter((m) => m.children != null).map((m) => m.path),
    );
  }, [userRole]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.length > 0 ? keys[keys.length - 1] : '';
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenGroupKeys(keys);
    } else {
      setOpenGroupKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    setOpenKeys([location.pathname.toString()]);
    menuItems.forEach((m: any) => {
      var children = m.children?.map((c: any) => c.key);
      if (children?.includes(location.pathname)) {
        setOpenGroupKeys([m.key]);
      }
    });
  }, [location.pathname, menuItems]);

  return (
    <Menu
      mode="inline"
      selectedKeys={openKeys}
      openKeys={openGroupKeys}
      onOpenChange={onOpenChange}
      style={{width: 250}}
      items={menuItems}
    />
  );
};

export default SidebarContent;
