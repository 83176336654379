import {compile} from 'path-to-regexp';

export const Routes = {
  dashboard: '/',
  otp: `/otp`,
  notifications: `/notifications`,
  payments: `/payments`,
  shops: `/shop/list`,
  shopDetail: `/shops/:id`,
  //setting
  finyAdmins: '/finy/admins',
  finyFaq: '/finy/faq',
  finyAuditFaq: '/finy/audit',
  finyInfo: '/finy/info',
  //finy requests
  finyRequests: `/finy/request/list`,
  finyRequestDetail: `/finy/request/:id`,
  //auditors
  auditCompanies: `/audit/companies`,
  //farmers
  farmers: `/farmer/list`,
  farmerDetail: `/farmer/:id`,
  //certificate
  certificates: `/certificate/list`,
  certificateDetail: `/certificate/:id`,
  standarts: `/standart/list`,
  standartDetail: `/standart/:id`,
  //finance institudes
  financeInstitutes: `/finance/list`,
  //loan
  loans: `/loan`,
  loanCreate: `/loan/create`,
  loanDetail: `/loan/:id`,
  loanRequests: `/loan/request/list`,
  loanRequestDetail: `/loan/request/:id`,
  //shop loan
  shopClients: `/shop/clients`,
  shopLoanAccounts: `/shop/accounts`,
  shopRepayment: `/shop/repayment`,
  shopLoans: `/shop/loans`,
  shopTotalLoans: `/shop/report/totalloan`,
  shopDueLoans: `/shop/report/dueloan`,
  shopNonperforming: `/shop/report/nonperforming`,
  shopOverdueLoans: `/shop/report/overdue`,
  shopReport: `/shop/report/loans`,
  shopLikelyNonperforming: `/shop/report/likelynon`,
  shopAccountant: `/shop/report/accountant`,
  //auth
  login: '/login',
  notfound: '/notfound',
  compilePath(path: string, param: object) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};
